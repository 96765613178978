<template>
  <div>
    <v-row v-if="campaigns.length === 0">
      <v-col>No campaigns yet</v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="4" v-for="campaign in campaigns" :key="campaign.id">
        <campaign-list-item
          :campaign="campaign"
          :loading="loading"
          @changed="() => checkStatusInterval.start()"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeUnmount } from "vue";
import { inject } from "@/injection";
import { CampaignShort, CampaignStatus } from "@/apiGen";
import AsyncInterval from "@/asyncInterval";
import CampaignListItem from "@/components/campaign/ListItem.vue";
import { store } from "@/store";
import { Mutation } from "@/store/mutations";
import { createDummies } from "@/models/campaign";

const api = inject("api");

const loading = ref(true);
const campaigns = ref<CampaignShort[]>(
  createDummies(store.getters.counters.campaigns),
);

const inProgressCampaigns = computed(() => {
  return campaigns.value.filter(
    (campaign) => campaign.status === CampaignStatus.InProgress,
  );
});

const checkStatusInterval = new AsyncInterval(
  (runner) => {
    return api
      .listCampaigns()
      .then((response) => {
        campaigns.value = response.data.campaigns;
        store.commit(Mutation.SetCounters, {
          campaigns: campaigns.value.length,
        });
        if (!inProgressCampaigns.value.length) {
          runner.stop();
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        loading.value = false;
      });
  },
  { timeout: 2000, start: true },
);
onBeforeUnmount(() => {
  checkStatusInterval.stop();
});
</script>
