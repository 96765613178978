<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn
            :loading="loading"
            prepend-icon="mdi-view-list"
            elevated
            color="info"
            @click="getContacts"
          >
            Refresh contacts
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="contacts.length">
        <v-col cols="12">
          {{ selectedContacts.length }} of {{ contacts.length }} contacts
          selected
        </v-col>
        <v-col cols="12" md="6">
          <LoyaltyLevelFilter :contacts="contacts" />
        </v-col>
        <v-col cols="12" md="6">
          <SortingFilter />
        </v-col>
        <v-col cols="12" md="6">
          <LimitFilter />
        </v-col>
        <v-col cols="12" md="6">
          <FailedOnlyFilter />
        </v-col>
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>
                Show {{ selectedContacts.length }} selected contacts
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <span
                  v-for="(contact, index) in selectedContacts"
                  :key="contact.id"
                >
                  <span v-if="index !== 0">,</span>
                  <a
                    target="_blank"
                    :href="`https://modelcenter.livejasmin.com/en/messenger/current-chats/${contact.id}`"
                  >
                    {{ contact.name }}
                  </a>
                  ({{ fromNow(contact.last_message_at) }})
                </span>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import LoyaltyLevelFilter from "./filters/LoyaltyLevelFilter.vue";
import SortingFilter from "./filters/SortingFilter.vue";
import LimitFilter from "./filters/LimitFilter.vue";
import FailedOnlyFilter from "./filters/FailedOnlyFilter.vue";
import { fromNow } from "@/utils";
import { inject } from "@/injection";
import { Contact } from "@/apiGen";
import { Mutation } from "@/store/mutations";

const model = inject("model");
const store = inject("store");
const api = inject("api");

const loading = ref(false);
const contacts = computed<Contact[]>(() => model.value.contacts);

const selectedContacts = computed(() => {
  return store.getters.selectedContacts;
});

async function getContacts() {
  loading.value = true;
  return api
    .updateModelContacts(model.value.id)
    .then((response) => {
      model.value = response.data;
      store.commit(Mutation.SetSelectedModel, model.value);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
