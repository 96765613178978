<template>
  <v-form validate-on="submit" @submit.prevent="onSubmit">
    <v-text-field
      v-model="user.email"
      label="Email"
      disabled
      autocomplete="off"
    />
    <v-text-field
      v-model="formData.name"
      label="Name"
      required
      :rules="[(v) => !!v || 'Name is required']"
    />
    <v-text-field
      v-model="formData.credits"
      label="Credits"
      type="number"
      required
      :rules="[(v) => !!v || 'Credits are required']"
    />
    <v-text-field v-model="formData.password" label="Password" />
    <v-btn :loading="loading" type="submit" color="info" class="mx-2">
      Save
    </v-btn>
    <v-btn :to="{ name: ADMIN_ROUTES.USERS }">Cancel</v-btn>
  </v-form>
</template>
<script setup lang="ts">
import { ADMIN_ROUTES } from "@/router";
import { ref } from "vue";
import { User, UpdateUserData } from "@/apiGen";
import { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";

const user = inject("user");
const api = inject("adminApi");

const emit = defineEmits<(event: "submit", user: User) => void>();

const loading = ref(false);

const formData = ref<UpdateUserData>({
  name: user.value.name,
  password: "",
  credits: user.value.credits,
});

async function onSubmit(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .adminUpdateUser(user.value.id, formData.value)
    .then((response) => {
      emit("submit", response.data);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
