<template>
  <v-card>
    <v-card-title>Edit model {{ model.name }}</v-card-title>
    <v-card-text>
      <model-edit-form @submit="onSubmit" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { router } from "@/router";
import ModelEditForm from "@/components/ModelEditForm.vue";
import { inject } from "@/injection";

const model = inject("model");
const ROUTES = inject("routes");

async function onSubmit() {
  router.push({
    name: ROUTES.MODEL_VIEW,
    params: { modelId: model.value.id },
  });
}
</script>
