<template>
  <div>
    <v-row>
      <v-col>
        <v-btn color="success" :to="{ name: ROUTES.MODEL_ADD }">
          Add new model
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="models.length === 0">
      <v-col>
        No models yet,
        <router-link :to="{ name: ROUTES.MODEL_ADD }">
          add a new one
        </router-link>
        !
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="4" v-for="model in models" :key="model.id">
        <model-list-item :model="model" :loading="loading" />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { inject } from "@/injection";
import { UserModelShort } from "@/apiGen";
import { store } from "@/store";
import { Mutation } from "@/store/mutations";
import { createDummies } from "@/models/model";
import ModelListItem from "@/components/model/ListItem.vue";

const api = inject("api");
const ROUTES = inject("routes");

const loading = ref(true);
const models = ref<UserModelShort[]>(
  createDummies(store.getters.counters.models),
);

api
  .listModels()
  .then((response) => {
    models.value = response.data.models;
    store.commit(Mutation.SetCounters, {
      models: models.value.length,
    });
  })
  .catch((e) => {
    console.error(e);
  })
  .finally(() => {
    loading.value = false;
  });
</script>
