<template>
  <div>
    <v-row>
      <v-col>
        <v-btn color="success" :to="{ name: ADMIN_ROUTES.USER_ADD }">
          Add new user
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="user in users" :key="user.id">
      <v-col>
        <v-card>
          <v-card-title>{{ user.name }}</v-card-title>
          <v-card-text>
            <p>Email: {{ user.email }}</p>
            <p>Credits: {{ user.credits }}</p>
            <p>Type: {{ user.type }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="info"
              :to="{
                name: ADMIN_ROUTES.USER_VIEW,
                params: { userId: user.id },
              }"
            >
              View
            </v-btn>
            <v-btn
              color="info"
              :to="{
                name: ADMIN_ROUTES.USER_EDIT,
                params: { userId: user.id },
              }"
            >
              Edit
            </v-btn>
            <v-btn
              color="info"
              :to="{
                name: ADMIN_ROUTES.MODEL_LIST,
                params: { userId: user.id },
              }"
            >
              Models
            </v-btn>
            <v-btn
              color="info"
              :to="{
                name: ADMIN_ROUTES.CAMPAIGN_LIST,
                params: { userId: user.id },
              }"
            >
              Campaigns
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { ADMIN_ROUTES } from "@/router";
import { User } from "@/apiGen";
import { inject } from "@/injection";
import { store } from "@/store";
import { createDummies } from "@/models/user";

const api = inject("adminApi");
const loading = ref(true);
const users = ref<User[]>(createDummies(store.getters.counters.users));

api
  .adminListUsers()
  .then((response) => {
    users.value = response.data.users;
  })
  .finally(() => {
    loading.value = false;
  });
</script>
