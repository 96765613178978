<template>
  <v-form validate-on="submit" @submit.prevent="onSubmit">
    <v-text-field
      v-model="formData.email"
      label="Email"
      required
      autocomplete="off"
      :rules="[
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'Invalid Email address',
      ]"
    />
    <v-text-field
      v-model="formData.name"
      label="Name"
      required
      :rules="[(v) => !!v || 'Name is required']"
    />
    <v-text-field
      v-model="formData.credits"
      label="Credits"
      type="number"
      required
      :rules="[(v) => !!v || 'Credits are required']"
    />
    <v-text-field
      v-model="formData.password"
      label="Password"
      required
      :rules="[(v) => !!v || 'Password is required']"
    />
    <v-btn :loading="loading" type="submit" color="info" class="mx-2">
      Add new user
    </v-btn>
    <v-btn :to="{ name: ADMIN_ROUTES.USERS }">Cancel</v-btn>
  </v-form>
</template>
<script setup lang="ts">
import { ADMIN_ROUTES } from "@/router";
import { ref } from "vue";
import { User, CreateUserData } from "@/apiGen";
import { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";

const api = inject("adminApi");

const emit = defineEmits<(event: "submit", user: User) => void>();

const loading = ref(false);

const formData = ref<CreateUserData>({
  name: "",
  email: "",
  password: "",
  credits: 999,
});

async function onSubmit(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .adminCreateUser(formData.value)
    .then((response) => {
      emit("submit", response.data);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
