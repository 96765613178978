import { MutationTree } from "vuex";
import { State } from "./state";
import type { UserModel, UserAuthData, Campaign, User } from "@/apiGen";
import type { Counters } from "./types";

export const Mutation = {
  SetUserData: "setUserData",
  UpdateFilters: "updateFilters",
  SetSelectedUser: "setSelectedUser",
  SetSelectedModel: "setSelectedModel",
  SetSelectedCampaign: "setSelectedCampaign",
  SetCounters: "setCounters",
  Logout: "logout",
} as const;

export type Mutations<S = State> = {
  [Mutation.SetUserData](state: S, value: UserAuthData): void;
  [Mutation.UpdateFilters](state: S, value: Partial<State["filters"]>): void;
  [Mutation.SetSelectedUser](state: S, value: User): void;
  [Mutation.SetSelectedModel](state: S, value: UserModel): void;
  [Mutation.SetSelectedCampaign](state: S, value: Campaign): void;
  [Mutation.Logout](state: S): void;
  [Mutation.SetCounters](state: S, value: Partial<Counters>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [Mutation.SetUserData](state, value) {
    state.userData = value;
  },
  [Mutation.UpdateFilters](state, value) {
    state.filters = { ...state.filters, ...value };
  },
  [Mutation.SetSelectedUser](state, value) {
    state.selectedUser = value;
  },
  [Mutation.SetSelectedModel](state, value) {
    state.selectedModel = value;
  },
  [Mutation.SetSelectedCampaign](state, value) {
    state.selectedCampaign = value;
  },
  [Mutation.Logout](state) {
    state.userData = {
      token: "",
      id: "",
      credits: 0,
      type: "basic",
      email: "",
      name: "",
    };
  },
  [Mutation.SetCounters](state, value) {
    state.counters = { ...state.counters, ...value };
  },
};
