<template>
  <v-card :loading="loading">
    <v-layout justify-center>
      <v-progress-linear
        :model-value="Math.round((campaign.sent_count / campaign.contacts_count) * 100)"
        color="light-blue"
        height="5"
        :striped="campaign.status == CampaignStatus.InProgress"
      ></v-progress-linear>
    </v-layout>
    <v-card-title>{{ campaign.name }}</v-card-title>
    <v-card-text>
      <p>
        Messaged {{ campaign.sent_count }} out of {{ campaign.contacts_count }} contacts
      </p>
      <p>
        Status: {{ campaign.status }}
        <span v-if="campaign.status_note">({{ campaign.status_note }})</span>
      </p>
      <p>
        Created: {{ formatDate(campaign.created_at) }} ({{
          fromNow(campaign.created_at)
        }})
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" :disabled="loading" @click="viewCampaign">View</v-btn>
      <async-button
        :disabled="loading"
        :onClick="startCampaign"
        v-if="
          campaign.status === CampaignStatus.Created ||
          campaign.status === CampaignStatus.Stopped
        "
        color="info"
      >
        {{ campaign.status === CampaignStatus.Created ? "Start" : "Resume" }}
      </async-button>
      <async-button
        :disabled="loading"
        :onClick="stopCampaign"
        v-if="campaign.status === CampaignStatus.InProgress"
        color="warning"
      >
        Stop
      </async-button>
      <async-button
        :disabled="loading"
        :onClick="deleteCampaign"
        v-if="campaign.status !== CampaignStatus.InProgress"
        color="error"
        confirm="Are you sure you want to delete this campaign?"
      >
        Delete
      </async-button>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { inject } from "@/injection";
import { CampaignShort, CampaignStatus } from "@/apiGen";
import AsyncButton from "@/components/AsyncButton.vue";
import { Mutation } from "@/store/mutations";
import { enrichCampaign } from "@/models/campaign";
import { fromNow, formatDate } from "@/utils";

interface IProps {
  campaign: CampaignShort;
  loading?: boolean;
}

const props = defineProps<IProps>();
const emit = defineEmits<(event: "changed", campaign: CampaignShort) => void>();

const { campaign } = props;

const api = inject("api");
const store = inject("store");
const router = inject("router");
const ROUTES = inject("routes");

function viewCampaign() {
  store.commit(Mutation.SetSelectedCampaign, enrichCampaign(campaign));
  router.push({
    name: ROUTES.CAMPAIGN_VIEW,
    params: {
      modelId: campaign.model_id,
      campaignId: campaign.id,
    },
  });
}

async function startCampaign() {
  const response = await api.startCampaign(campaign.id);
  campaign.status = response.data.status;
  campaign.status_note = response.data.status_note;
  campaign.sent_count = response.data.result.success.length;
  emit("changed", campaign);
}

async function stopCampaign() {
  const response = await api.stopCampaign(campaign.id);
  campaign.status = response.data.status;
  campaign.status_note = response.data.status_note;
  campaign.sent_count = response.data.result.success.length;
  emit("changed", campaign);
}

async function deleteCampaign() {
  await api.deleteCampaign(campaign.id);
  emit("changed", campaign);
}
</script>
