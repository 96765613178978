<template>
  <v-col cols="4">
    <v-card>
      <v-card-title>Login to WebChat AI</v-card-title>
      <v-card-text>
        <v-form validate-on="submit" @submit.prevent="login">
          <v-text-field
            v-model="formData.email"
            type="email"
            label="Email address"
            required
          ></v-text-field>
          <v-text-field
            v-model="formData.password"
            label="Password"
            type="password"
            required
          />
          <v-btn elevated :loading="loading" color="info" type="submit">
            Login
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div style="color-scheme: light">
          <GoogleSignInButton
            theme="filled_blue"
            context="signin"
            size="large"
            @success="handleGLoginSuccess"
            @error="handleGLoginError"
          ></GoogleSignInButton>
        </div>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script setup lang="ts">
import { ROUTES, router } from "@/router";
import { ApiError } from "@/apiClient";
import {
  GoogleSignInButton,
  type CredentialResponse,
} from "vue3-google-signin";
import { inject } from "@/injection";
import { useToast } from "vue-toast-notification";
import type { SubmitEventPromise } from "@/types";
import { ref } from "vue";
import { Mutation } from "@/store/mutations";

const api = inject("api");
const store = inject("store");
const toast = useToast();

const loading = ref(false);
const formData = ref({
  email: "",
  password: "",
});

async function login(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .login({
      email: formData.value.email,
      password: formData.value.password,
    })
    .then((response) => {
      store.commit(Mutation.SetUserData, response.data);
      router.push({ name: ROUTES.MODEL_LIST });
    })
    .catch((e) => {
      if (!(e instanceof ApiError)) throw e;
      if (e.status === 400) {
        store.commit(Mutation.Logout, undefined);
      }
    })
    .finally(() => {
      loading.value = false;
    });
}

async function handleGLoginSuccess(resp: CredentialResponse) {
  if (!resp.credential) return;
  return api
    .googleLogin({
      token: resp.credential,
    })
    .then((response) => {
      store.commit(Mutation.SetUserData, response.data);
      router.push({ name: ROUTES.MODEL_LIST });
    })
    .catch((e) => {
      if (!(e instanceof ApiError)) throw e;
      if (e.status === 400) {
        store.commit(Mutation.Logout, undefined);
        router.push({ name: ROUTES.DEMO });
      }
    });
}

const handleGLoginError = () => {
  store.commit(Mutation.Logout, undefined);
  toast.error("User is not authorized.");
  router.push({ name: ROUTES.DEMO });
};
</script>
