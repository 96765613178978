import { PublicApiFactory, AdminApiFactory, Configuration } from "@/apiGen";
import type { PublicApi, AdminApi, User } from "@/apiGen";
import axios, { AxiosError, AxiosInstance } from "axios";
import { ROUTES, router } from "./router";
import { useStore } from "./store";
export type { PublicApi, AdminApi } from "@/apiGen";
import { useToast } from "vue-toast-notification";
import { Mutation } from "./store/mutations";

export class ApiError extends Error {
  status: number;

  constructor(message: string, status = 400) {
    super(message);
    this.status = status;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

interface IApiErrorResponse {
  status: number;
  data: { message: string };
}

function getAxios(): AxiosInstance {
  const axiosInstance = axios.create({});
  // return axiosInstance;
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!(error instanceof AxiosError))
        throw new ApiError("Request failed", 500);

      if (!error.response) throw new ApiError(`Request failed: ${error}`, 500);
      const store = useStore();
      const toast = useToast();
      if (error.response.status === 401) {
        store.commit(Mutation.Logout, undefined);
        router.push({ name: ROUTES.LOGIN });
        toast.error("Session expired, please login.");
        throw new ApiError("Session expired", 401);
      }

      const response = error.response as IApiErrorResponse;
      const errorMessage = response.data.message || "Internal server error";
      toast.error(errorMessage);

      throw new ApiError(errorMessage, error.response.status);
    },
  );
  return axiosInstance;
}

export function getPublicApi(): PublicApi {
  const axiosInstance = getAxios();
  const store = useStore();
  const basePath = process.env.VUE_APP_API_URL || "/api/1";
  const config = new Configuration({
    accessToken: () => store.getters.accessToken,
    basePath,
  });
  const result = PublicApiFactory(config, basePath, axiosInstance);
  return result as PublicApi;
}

export function getAdminApi(): AdminApi {
  const axiosInstance = getAxios();
  const store = useStore();
  const basePath = process.env.VUE_APP_API_URL || "/api/1";
  const config = new Configuration({
    accessToken: () => store.getters.accessToken,
    basePath,
  });
  const result = AdminApiFactory(config, basePath, axiosInstance);
  return result as AdminApi;
}

export function getActAsApi(user: User): PublicApi {
  const axiosInstance = getAxios();
  const store = useStore();
  const basePath = process.env.VUE_APP_API_URL || "/api/1";
  const config = new Configuration({
    accessToken: () => store.getters.accessToken,
    basePath,
  });
  const api = PublicApiFactory(config, basePath, axiosInstance);
  const adminApi = AdminApiFactory(config, basePath, axiosInstance);

  api.createCampaign = (...args) =>
    adminApi.adminCreateCampaign(user.id, ...args);
  api.createModel = (...args) => adminApi.adminCreateModel(user.id, ...args);
  api.deleteCampaign = (...args) =>
    adminApi.adminDeleteCampaign(user.id, ...args);
  api.deleteModel = (...args) => adminApi.adminDeleteModel(user.id, ...args);
  api.getCampaign = (...args) => adminApi.adminGetCampaign(user.id, ...args);
  api.getModel = (...args) => adminApi.adminGetModel(user.id, ...args);
  api.listCampaigns = (...args) =>
    adminApi.adminListCampaigns(user.id, ...args);
  api.listModels = (...args) => adminApi.adminListModels(user.id, ...args);
  api.loginModel = (...args) => adminApi.adminLoginModel(user.id, ...args);
  api.sendMessage = (...args) => adminApi.adminSendMessage(user.id, ...args);
  api.startCampaign = (...args) =>
    adminApi.adminStartCampaign(user.id, ...args);
  api.stopCampaign = (...args) => adminApi.adminStopCampaign(user.id, ...args);
  api.updateCampaign = (...args) =>
    adminApi.adminUpdateCampaign(user.id, ...args);
  api.updateModel = (...args) => adminApi.adminUpdateModel(user.id, ...args);
  api.updateModelContacts = (...args) =>
    adminApi.adminUpdateModelContacts(user.id, ...args);
  api.uploadCampaignMedia = (...args) =>
    adminApi.adminUploadCampaignMedia(user.id, ...args);

  return api as PublicApi;
}
