import dayjs from "@/dayjs";

export function chunkify<V>(arr: V[], size: number): V[][] {
  const result: V[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}

export function fromNow(d: Date | string) {
  return dayjs(d).fromNow();
}

export function formatDate(d: Date | string) {
  const date = dayjs(d);
  const now = dayjs();
  if (date.isSame(now, "day")) {
    return date.format("HH:mm");
  }
  if (date.isSame(now, "year")) {
    return dayjs(d).format("MMM D, HH:mm");
  }
  return dayjs(d).format("MMM D, YYYY HH:mm");
}

export function getReadableFileSize(size: number): string {
  let i = -1;
  const byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    size /= 1024;
    i++;
  } while (size > 1024);

  return Math.max(size, 0.1).toFixed(1) + byteUnits[i];
}
