/* tslint:disable */
/* eslint-disable */
/**
 * WebChat AI API
 * WebChat AI API
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: vlad.emelianov.nz@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface Campaign
 */
export interface Campaign {
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  name: string;
  /**
   *
   * @type {CampaignStatus}
   * @memberof Campaign
   */
  status: CampaignStatus;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  status_note: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  model_id: string;
  /**
   *
   * @type {Array<Contact>}
   * @memberof Campaign
   */
  contacts: Array<Contact>;
  /**
   *
   * @type {CampaignSettings}
   * @memberof Campaign
   */
  settings: CampaignSettings;
  /**
   *
   * @type {CampaignMessage}
   * @memberof Campaign
   */
  message: CampaignMessage;
  /**
   *
   * @type {CampaignResult}
   * @memberof Campaign
   */
  result: CampaignResult;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  started_at: string;
  /**
   *
   * @type {string}
   * @memberof Campaign
   */
  finished_at: string;
  /**
   * Is deleted and hidden from end user
   * @type {boolean}
   * @memberof Campaign
   */
  is_deleted?: boolean;
}

/**
 *
 * @export
 * @interface CampaignList
 */
export interface CampaignList {
  /**
   *
   * @type {Array<CampaignShort>}
   * @memberof CampaignList
   */
  campaigns: Array<CampaignShort>;
}
/**
 *
 * @export
 * @interface CampaignMessage
 */
export interface CampaignMessage {
  /**
   *
   * @type {string}
   * @memberof CampaignMessage
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof CampaignMessage
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof CampaignMessage
   */
  file_path: string;
  /**
   *
   * @type {string}
   * @memberof CampaignMessage
   */
  file_name: string;
  /**
   *
   * @type {number}
   * @memberof CampaignMessage
   */
  file_size: number;
  /**
   *
   * @type {number}
   * @memberof CampaignMessage
   */
  credits_cost: number;
}
/**
 *
 * @export
 * @interface CampaignResult
 */
export interface CampaignResult {
  /**
   *
   * @type {Array<number>}
   * @memberof CampaignResult
   */
  success: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CampaignResult
   */
  failed: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CampaignResult
   */
  reached: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof CampaignResult
   */
  retries: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof CampaignResult
   */
  errors: Array<string>;
}
/**
 *
 * @export
 * @interface CampaignSettings
 */
export interface CampaignSettings {
  /**
   *
   * @type {number}
   * @memberof CampaignSettings
   */
  limit: number;
  /**
   *
   * @type {Array<string>}
   * @memberof CampaignSettings
   */
  loyalty_levels: Array<string>;
  /**
   *
   * @type {ContactSorting}
   * @memberof CampaignSettings
   */
  sorting: ContactSorting;
}

/**
 *
 * @export
 * @interface CampaignShort
 */
export interface CampaignShort {
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  name: string;
  /**
   *
   * @type {CampaignStatus}
   * @memberof CampaignShort
   */
  status: CampaignStatus;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  status_note: string;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  model_id: string;
  /**
   *
   * @type {number}
   * @memberof CampaignShort
   */
  contacts_count: number;
  /**
   *
   * @type {number}
   * @memberof CampaignShort
   */
  sent_count: number;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  started_at: string;
  /**
   *
   * @type {string}
   * @memberof CampaignShort
   */
  finished_at: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const CampaignStatus = {
  Created: "created",
  InProgress: "in_progress",
  Stopped: "stopped",
  Finished: "finished",
} as const;

export type CampaignStatus =
  (typeof CampaignStatus)[keyof typeof CampaignStatus];

/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {number}
   * @memberof Contact
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Contact
   */
  partner_id: number;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  loyalty_level: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  last_message_at: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContactSorting = {
  LastMessageAt: "last_message_at",
  LastMessageAtDesc: "last_message_at_desc",
  Name: "name",
  NameDesc: "name_desc",
} as const;

export type ContactSorting =
  (typeof ContactSorting)[keyof typeof ContactSorting];

/**
 *
 * @export
 * @interface CreateCampaignData
 */
export interface CreateCampaignData {
  /**
   *
   * @type {string}
   * @memberof CreateCampaignData
   */
  model_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateCampaignData
   */
  name: string;
  /**
   *
   * @type {Array<Contact>}
   * @memberof CreateCampaignData
   */
  contacts: Array<Contact>;
  /**
   *
   * @type {CampaignSettings}
   * @memberof CreateCampaignData
   */
  settings: CampaignSettings;
  /**
   *
   * @type {number}
   * @memberof CreateCampaignData
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof CreateCampaignData
   */
  text: string;
}
/**
 *
 * @export
 * @interface CreateModelData
 */
export interface CreateModelData {
  /**
   *
   * @type {string}
   * @memberof CreateModelData
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateModelData
   */
  password: string;
}
/**
 *
 * @export
 * @interface CreateUserData
 */
export interface CreateUserData {
  /**
   *
   * @type {string}
   * @memberof CreateUserData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserData
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof CreateUserData
   */
  credits: number;
  /**
   *
   * @type {string}
   * @memberof CreateUserData
   */
  password: string;
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  code?: string;
}
/**
 *
 * @export
 * @interface GoogleLoginData
 */
export interface GoogleLoginData {
  /**
   *
   * @type {string}
   * @memberof GoogleLoginData
   */
  token: string;
}
/**
 *
 * @export
 * @interface LoginData
 */
export interface LoginData {
  /**
   *
   * @type {string}
   * @memberof LoginData
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginData
   */
  password: string;
}
/**
 *
 * @export
 * @interface LoginModelData
 */
export interface LoginModelData {
  /**
   *
   * @type {string}
   * @memberof LoginModelData
   */
  password: string;
}
/**
 *
 * @export
 * @interface MessageReport
 */
export interface MessageReport {
  /**
   *
   * @type {Array<number>}
   * @memberof MessageReport
   */
  sent: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof MessageReport
   */
  failed: Array<number>;
}
/**
 *
 * @export
 * @interface Model
 */
export interface Model {
  /**
   *
   * @type {string}
   * @memberof Model
   */
  id: string;
  /**
   * Owner user ID
   * @type {string}
   * @memberof Model
   */
  user_id: string;
  /**
   * Owner user email
   * @type {string}
   * @memberof Model
   */
  user_email: string;
  /**
   * Model email on LJ
   * @type {string}
   * @memberof Model
   */
  email: string;
  /**
   * Model password on LJ
   * @type {string}
   * @memberof Model
   */
  password: string;
  /**
   *
   * @type {Array<Contact>}
   * @memberof Model
   */
  contacts: Array<Contact>;
  /**
   *
   * @type {number}
   * @memberof Model
   */
  contacts_count: number;
  /**
   * Last token update time
   * @type {string}
   * @memberof Model
   */
  token_updated_at: string;
  /**
   * Model name on LJ
   * @type {string}
   * @memberof Model
   */
  name: string;
  /**
   * Performer ID for LJ API requests
   * @type {string}
   * @memberof Model
   */
  performer_id: string;
  /**
   * Access token for LJ API requests
   * @type {string}
   * @memberof Model
   */
  token?: string;
  /**
   * Is deleted and hidden from end user
   * @type {boolean}
   * @memberof Model
   */
  is_deleted?: boolean;
}
/**
 *
 * @export
 * @interface ModelList
 */
export interface ModelList {
  /**
   *
   * @type {Array<Model>}
   * @memberof ModelList
   */
  models: Array<Model>;
}
/**
 *
 * @export
 * @interface SendMessageData
 */
export interface SendMessageData {
  /**
   *
   * @type {string}
   * @memberof SendMessageData
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof SendMessageData
   */
  price?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof SendMessageData
   */
  ids: Array<number>;
  /**
   *
   * @type {File}
   * @memberof SendMessageData
   */
  file?: File;
}
/**
 *
 * @export
 * @interface UpdateCampaignData
 */
export interface UpdateCampaignData {
  /**
   *
   * @type {string}
   * @memberof UpdateCampaignData
   */
  name?: string;
  /**
   *
   * @type {Array<Contact>}
   * @memberof UpdateCampaignData
   */
  contacts?: Array<Contact>;
  /**
   *
   * @type {CampaignSettings}
   * @memberof UpdateCampaignData
   */
  settings?: CampaignSettings;
  /**
   *
   * @type {CampaignMessage}
   * @memberof UpdateCampaignData
   */
  message?: CampaignMessage;
  /**
   *
   * @type {number}
   * @memberof UpdateCampaignData
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateCampaignData
   */
  text?: string;
}
/**
 *
 * @export
 * @interface UpdateModelData
 */
export interface UpdateModelData {
  /**
   *
   * @type {string}
   * @memberof UpdateModelData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateModelData
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateModelData
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateModelData
   */
  token_updated_at?: string;
}
/**
 *
 * @export
 * @interface UpdateUserData
 */
export interface UpdateUserData {
  /**
   *
   * @type {string}
   * @memberof UpdateUserData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserData
   */
  password?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateUserData
   */
  credits?: number;
}
/**
 *
 * @export
 * @interface UploadCampaignMediaData
 */
export interface UploadCampaignMediaData {
  /**
   *
   * @type {File}
   * @memberof UploadCampaignMediaData
   */
  file: File;
}
/**
 *
 * @export
 * @interface UsageLog
 */
export interface UsageLog {
  /**
   *
   * @type {string}
   * @memberof UsageLog
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UsageLog
   */
  modelEmail: string;
  /**
   *
   * @type {number}
   * @memberof UsageLog
   */
  sent: number;
  /**
   *
   * @type {number}
   * @memberof UsageLog
   */
  failed: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UsageLog
   */
  errors?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof UsageLog
   */
  cost: number;
  /**
   *
   * @type {string}
   * @memberof UsageLog
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof UsageLog
   */
  fileName: string;
  /**
   *
   * @type {string}
   * @memberof UsageLog
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  credits: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  credits_total?: number;
  /**
   *
   * @type {UserType}
   * @memberof User
   */
  type: UserType;
  /**
   * Is deleted and hidden from end user
   * @type {boolean}
   * @memberof User
   */
  is_deleted?: boolean;
}

/**
 *
 * @export
 * @interface UserAuthData
 */
export interface UserAuthData {
  /**
   *
   * @type {string}
   * @memberof UserAuthData
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserAuthData
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserAuthData
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof UserAuthData
   */
  credits: number;
  /**
   *
   * @type {UserType}
   * @memberof UserAuthData
   */
  type: UserType;
  /**
   *
   * @type {string}
   * @memberof UserAuthData
   */
  token: string;
}

/**
 *
 * @export
 * @interface UserList
 */
export interface UserList {
  /**
   *
   * @type {Array<User>}
   * @memberof UserList
   */
  users: Array<User>;
}
/**
 *
 * @export
 * @interface UserModel
 */
export interface UserModel {
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  name: string;
  /**
   *
   * @type {Array<Contact>}
   * @memberof UserModel
   */
  contacts: Array<Contact>;
  /**
   *
   * @type {number}
   * @memberof UserModel
   */
  contacts_count: number;
}
/**
 *
 * @export
 * @interface UserModelList
 */
export interface UserModelList {
  /**
   *
   * @type {Array<UserModelShort>}
   * @memberof UserModelList
   */
  models: Array<UserModelShort>;
}
/**
 *
 * @export
 * @interface UserModelShort
 */
export interface UserModelShort {
  /**
   *
   * @type {string}
   * @memberof UserModelShort
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserModelShort
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserModelShort
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof UserModelShort
   */
  contacts_count: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserType = {
  Basic: "basic",
  Admin: "admin",
  Premium: "premium",
} as const;

export type UserType = (typeof UserType)[keyof typeof UserType];

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateCampaign: async (
      userId: string,
      createCampaignData?: CreateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminCreateCampaign", "userId", userId);
      const localVarPath = `/admin/users/{userId}/campaigns`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateModel: async (
      userId: string,
      createModelData?: CreateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminCreateModel", "userId", userId);
      const localVarPath = `/admin/users/{userId}/models/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateUser: async (
      createUserData?: CreateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminDeleteCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteModel: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminDeleteModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteUser: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminGetCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetModel: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminGetModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetUser: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListCampaigns: async (
      userId: string,
      modelId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminListCampaigns", "userId", userId);
      const localVarPath = `/admin/users/{userId}/campaigns`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (modelId !== undefined) {
        localVarQueryParameter["modelId"] = modelId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListModels: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminListModels", "userId", userId);
      const localVarPath = `/admin/users/{userId}/models/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListUsers: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLoginModel: async (
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminLoginModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminLoginModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/login`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSendMessage: async (
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminSendMessage", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminSendMessage", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/message`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendMessageData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStartCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminStartCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminStartCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/start`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStopCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminStopCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminStopCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/stop`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateCampaign: async (
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminUpdateCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModel: async (
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminUpdateModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModelContacts: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateModelContacts", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminUpdateModelContacts", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/contacts`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateUser: async (
      userId: string,
      updateUserData?: UpdateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUploadCampaignMedia: async (
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUploadCampaignMedia", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminUploadCampaignMedia", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/upload`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadCampaignMediaData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateCampaign(
      userId: string,
      createCampaignData?: CreateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminCreateCampaign(
          userId,
          createCampaignData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateModel(
      userId: string,
      createModelData?: CreateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminCreateModel(
          userId,
          createModelData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateUser(
      createUserData?: CreateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUser(
        createUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDeleteCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteModel(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDeleteModel(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteUser(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteUser(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminGetCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetModel(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetModel(
        userId,
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetUser(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUser(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListCampaigns(
      userId: string,
      modelId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminListCampaigns(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListModels(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminListModels(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListUsers(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminListUsers(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLoginModel(
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLoginModel(
        userId,
        modelId,
        loginModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSendMessage(
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSendMessage(
          userId,
          modelId,
          sendMessageData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStartCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStartCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStopCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStopCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateCampaign(
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateCampaign(
          userId,
          campaignId,
          updateCampaignData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateModel(
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateModel(
          userId,
          modelId,
          updateModelData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateModelContacts(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateModelContacts(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateUser(
      userId: string,
      updateUserData?: UpdateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUser(
        userId,
        updateUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUploadCampaignMedia(
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUploadCampaignMedia(
          userId,
          campaignId,
          uploadCampaignMediaData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateCampaign(
      userId: string,
      createCampaignData?: CreateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminCreateCampaign(userId, createCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateModel(
      userId: string,
      createModelData?: CreateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminCreateModel(userId, createModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateUser(
      createUserData?: CreateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .adminCreateUser(createUserData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminDeleteCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteModel(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminDeleteModel(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .adminDeleteUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminGetCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetModel(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminGetModel(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .adminGetUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListCampaigns(
      userId: string,
      modelId?: string,
      options?: any,
    ): AxiosPromise<CampaignList> {
      return localVarFp
        .adminListCampaigns(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListModels(
      userId: string,
      options?: any,
    ): AxiosPromise<UserModelList> {
      return localVarFp
        .adminListModels(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListUsers(options?: any): AxiosPromise<UserList> {
      return localVarFp
        .adminListUsers(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLoginModel(
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminLoginModel(userId, modelId, loginModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSendMessage(
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: any,
    ): AxiosPromise<MessageReport> {
      return localVarFp
        .adminSendMessage(userId, modelId, sendMessageData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStartCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminStartCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStopCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminStopCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateCampaign(
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminUpdateCampaign(userId, campaignId, updateCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModel(
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminUpdateModel(userId, modelId, updateModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModelContacts(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminUpdateModelContacts(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateUser(
      userId: string,
      updateUserData?: UpdateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .adminUpdateUser(userId, updateUserData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUploadCampaignMedia(
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminUploadCampaignMedia(
          userId,
          campaignId,
          uploadCampaignMediaData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @summary Create a new campaign.
   * @param {string} userId User ID
   * @param {CreateCampaignData} [createCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminCreateCampaign(
    userId: string,
    createCampaignData?: CreateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminCreateCampaign(userId, createCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new model.
   * @param {string} userId User ID
   * @param {CreateModelData} [createModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminCreateModel(
    userId: string,
    createModelData?: CreateModelData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminCreateModel(userId, createModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new user.
   * @param {CreateUserData} [createUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminCreateUser(
    createUserData?: CreateUserData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminCreateUser(createUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminDeleteCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminDeleteCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminDeleteModel(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminDeleteModel(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete user.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminDeleteUser(userId: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminDeleteUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get campaign details.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminGetCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminGetCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminGetModel(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminGetModel(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminGetUser(userId: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminGetUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List model campaigns.
   * @param {string} userId User ID
   * @param {string} [modelId] Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminListCampaigns(
    userId: string,
    modelId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminListCampaigns(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of models.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminListModels(userId: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminListModels(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a list of users.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminListUsers(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .adminListUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates model token.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {LoginModelData} [loginModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminLoginModel(
    userId: string,
    modelId: string,
    loginModelData?: LoginModelData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminLoginModel(userId, modelId, loginModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send message as a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {SendMessageData} [sendMessageData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminSendMessage(
    userId: string,
    modelId: string,
    sendMessageData?: SendMessageData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminSendMessage(userId, modelId, sendMessageData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminStartCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminStartCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminStopCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminStopCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {UpdateCampaignData} [updateCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUpdateCampaign(
    userId: string,
    campaignId: string,
    updateCampaignData?: UpdateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminUpdateCampaign(userId, campaignId, updateCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {UpdateModelData} [updateModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUpdateModel(
    userId: string,
    modelId: string,
    updateModelData?: UpdateModelData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminUpdateModel(userId, modelId, updateModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update model contacts.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUpdateModelContacts(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminUpdateModelContacts(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update user.
   * @param {string} userId User ID
   * @param {UpdateUserData} [updateUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUpdateUser(
    userId: string,
    updateUserData?: UpdateUserData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminUpdateUser(userId, updateUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload campaign media.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminUploadCampaignMedia(
    userId: string,
    campaignId: string,
    uploadCampaignMediaData?: UploadCampaignMediaData,
    options?: AxiosRequestConfig,
  ) {
    return AdminApiFp(this.configuration)
      .adminUploadCampaignMedia(
        userId,
        campaignId,
        uploadCampaignMediaData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile: async (
      updateUserData?: UpdateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleLogin: async (
      googleLoginData?: GoogleLoginData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/google/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        googleLoginData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      loginData?: LoginData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editProfile(
      updateUserData?: UpdateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editProfile(
        updateUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfile(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProfile(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googleLogin(
      googleLoginData?: GoogleLoginData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthData>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(
        googleLoginData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginData?: LoginData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthData>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        loginData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile(
      updateUserData?: UpdateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .editProfile(updateUserData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options?: any): AxiosPromise<User> {
      return localVarFp
        .getProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleLogin(
      googleLoginData?: GoogleLoginData,
      options?: any,
    ): AxiosPromise<UserAuthData> {
      return localVarFp
        .googleLogin(googleLoginData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginData?: LoginData, options?: any): AxiosPromise<UserAuthData> {
      return localVarFp
        .login(loginData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary Edit user profile.
   * @param {UpdateUserData} [updateUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public editProfile(
    updateUserData?: UpdateUserData,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .editProfile(updateUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public getProfile(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .getProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login with Google JWT.
   * @param {GoogleLoginData} [googleLoginData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public googleLogin(
    googleLoginData?: GoogleLoginData,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .googleLogin(googleLoginData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login with password.
   * @param {LoginData} [loginData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public login(loginData?: LoginData, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .login(loginData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateCampaign: async (
      userId: string,
      createCampaignData?: CreateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminCreateCampaign", "userId", userId);
      const localVarPath = `/admin/users/{userId}/campaigns`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminDeleteCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminGetCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListCampaigns: async (
      userId: string,
      modelId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminListCampaigns", "userId", userId);
      const localVarPath = `/admin/users/{userId}/campaigns`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (modelId !== undefined) {
        localVarQueryParameter["modelId"] = modelId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStartCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminStartCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminStartCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/start`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStopCampaign: async (
      userId: string,
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminStopCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminStopCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/stop`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateCampaign: async (
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateCampaign", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminUpdateCampaign", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUploadCampaignMedia: async (
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUploadCampaignMedia", "userId", userId);
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("adminUploadCampaignMedia", "campaignId", campaignId);
      const localVarPath = `/admin/users/{userId}/campaigns/{campaignId}/upload`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadCampaignMediaData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCampaign: async (
      createCampaignData?: CreateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("deleteCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("getCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCampaigns: async (
      modelId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (modelId !== undefined) {
        localVarQueryParameter["modelId"] = modelId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("startCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/start`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("stopCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/stop`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCampaign: async (
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("updateCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCampaignMedia: async (
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("uploadCampaignMedia", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/upload`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadCampaignMediaData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateCampaign(
      userId: string,
      createCampaignData?: CreateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminCreateCampaign(
          userId,
          createCampaignData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDeleteCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminGetCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListCampaigns(
      userId: string,
      modelId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminListCampaigns(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStartCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStartCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStopCampaign(
      userId: string,
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStopCampaign(
          userId,
          campaignId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateCampaign(
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateCampaign(
          userId,
          campaignId,
          updateCampaignData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUploadCampaignMedia(
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUploadCampaignMedia(
          userId,
          campaignId,
          uploadCampaignMediaData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCampaign(
      createCampaignData?: CreateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(
        createCampaignData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCampaigns(
      modelId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCampaign(
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(
        campaignId,
        updateCampaignData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadCampaignMedia(
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadCampaignMedia(
          campaignId,
          uploadCampaignMediaData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CampaignApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {string} userId User ID
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateCampaign(
      userId: string,
      createCampaignData?: CreateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminCreateCampaign(userId, createCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminDeleteCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminGetCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} userId User ID
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListCampaigns(
      userId: string,
      modelId?: string,
      options?: any,
    ): AxiosPromise<CampaignList> {
      return localVarFp
        .adminListCampaigns(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStartCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminStartCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStopCampaign(
      userId: string,
      campaignId: string,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminStopCampaign(userId, campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateCampaign(
      userId: string,
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminUpdateCampaign(userId, campaignId, updateCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} userId User ID
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUploadCampaignMedia(
      userId: string,
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .adminUploadCampaignMedia(
          userId,
          campaignId,
          uploadCampaignMediaData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCampaign(
      createCampaignData?: CreateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .createCampaign(createCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .deleteCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .getCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCampaigns(modelId?: string, options?: any): AxiosPromise<CampaignList> {
      return localVarFp
        .listCampaigns(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .startCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .stopCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCampaign(
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .updateCampaign(campaignId, updateCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCampaignMedia(
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .uploadCampaignMedia(campaignId, uploadCampaignMediaData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
  /**
   *
   * @summary Create a new campaign.
   * @param {string} userId User ID
   * @param {CreateCampaignData} [createCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminCreateCampaign(
    userId: string,
    createCampaignData?: CreateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminCreateCampaign(userId, createCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminDeleteCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminDeleteCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get campaign details.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminGetCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminGetCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List model campaigns.
   * @param {string} userId User ID
   * @param {string} [modelId] Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminListCampaigns(
    userId: string,
    modelId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminListCampaigns(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminStartCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminStartCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminStopCampaign(
    userId: string,
    campaignId: string,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminStopCampaign(userId, campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update campaign.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {UpdateCampaignData} [updateCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminUpdateCampaign(
    userId: string,
    campaignId: string,
    updateCampaignData?: UpdateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminUpdateCampaign(userId, campaignId, updateCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload campaign media.
   * @param {string} userId User ID
   * @param {string} campaignId Campaign ID
   * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public adminUploadCampaignMedia(
    userId: string,
    campaignId: string,
    uploadCampaignMediaData?: UploadCampaignMediaData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .adminUploadCampaignMedia(
        userId,
        campaignId,
        uploadCampaignMediaData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new campaign.
   * @param {CreateCampaignData} [createCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public createCampaign(
    createCampaignData?: CreateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .createCampaign(createCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public deleteCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return CampaignApiFp(this.configuration)
      .deleteCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get campaign details.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public getCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return CampaignApiFp(this.configuration)
      .getCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List model campaigns.
   * @param {string} [modelId] Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public listCampaigns(modelId?: string, options?: AxiosRequestConfig) {
    return CampaignApiFp(this.configuration)
      .listCampaigns(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public startCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return CampaignApiFp(this.configuration)
      .startCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public stopCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return CampaignApiFp(this.configuration)
      .stopCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update campaign.
   * @param {string} campaignId Campaign ID
   * @param {UpdateCampaignData} [updateCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public updateCampaign(
    campaignId: string,
    updateCampaignData?: UpdateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .updateCampaign(campaignId, updateCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload campaign media.
   * @param {string} campaignId Campaign ID
   * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CampaignApi
   */
  public uploadCampaignMedia(
    campaignId: string,
    uploadCampaignMediaData?: UploadCampaignMediaData,
    options?: AxiosRequestConfig,
  ) {
    return CampaignApiFp(this.configuration)
      .uploadCampaignMedia(campaignId, uploadCampaignMediaData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ModelsApi - axios parameter creator
 * @export
 */
export const ModelsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateModel: async (
      userId: string,
      createModelData?: CreateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminCreateModel", "userId", userId);
      const localVarPath = `/admin/users/{userId}/models/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteModel: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminDeleteModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetModel: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminGetModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListModels: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminListModels", "userId", userId);
      const localVarPath = `/admin/users/{userId}/models/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLoginModel: async (
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminLoginModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminLoginModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/login`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSendMessage: async (
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminSendMessage", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminSendMessage", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/message`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendMessageData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModel: async (
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateModel", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminUpdateModel", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModelContacts: async (
      userId: string,
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateModelContacts", "userId", userId);
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("adminUpdateModelContacts", "modelId", modelId);
      const localVarPath = `/admin/users/{userId}/models/{modelId}/contacts`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel: async (
      createModelData?: CreateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/models/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("deleteModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("getModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModels: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/models/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginModel: async (
      modelId: string,
      loginModelData?: LoginModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("loginModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/login`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage: async (
      modelId: string,
      sendMessageData?: SendMessageData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("sendMessage", "modelId", modelId);
      const localVarPath = `/models/{modelId}/message`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendMessageData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel: async (
      modelId: string,
      updateModelData?: UpdateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("updateModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelContacts: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("updateModelContacts", "modelId", modelId);
      const localVarPath = `/models/{modelId}/contacts`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModelsApi - functional programming interface
 * @export
 */
export const ModelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModelsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateModel(
      userId: string,
      createModelData?: CreateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminCreateModel(
          userId,
          createModelData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteModel(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDeleteModel(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetModel(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetModel(
        userId,
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListModels(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminListModels(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminLoginModel(
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminLoginModel(
        userId,
        modelId,
        loginModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSendMessage(
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReport>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSendMessage(
          userId,
          modelId,
          sendMessageData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateModel(
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateModel(
          userId,
          modelId,
          updateModelData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateModelContacts(
      userId: string,
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminUpdateModelContacts(
          userId,
          modelId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createModel(
      createModelData?: CreateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createModel(
        createModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteModel(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModel(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModel(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModel(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listModels(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listModels(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginModel(
      modelId: string,
      loginModelData?: LoginModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginModel(
        modelId,
        loginModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendMessage(
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(
        modelId,
        sendMessageData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModel(
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateModel(
        modelId,
        updateModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModelContacts(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateModelContacts(modelId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ModelsApi - factory interface
 * @export
 */
export const ModelsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ModelsApiFp(configuration);
  return {
    /**
     *
     * @summary Creates a new model.
     * @param {string} userId User ID
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateModel(
      userId: string,
      createModelData?: CreateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminCreateModel(userId, createModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteModel(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminDeleteModel(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetModel(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminGetModel(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListModels(
      userId: string,
      options?: any,
    ): AxiosPromise<UserModelList> {
      return localVarFp
        .adminListModels(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminLoginModel(
      userId: string,
      modelId: string,
      loginModelData?: LoginModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminLoginModel(userId, modelId, loginModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSendMessage(
      userId: string,
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: any,
    ): AxiosPromise<MessageReport> {
      return localVarFp
        .adminSendMessage(userId, modelId, sendMessageData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModel(
      userId: string,
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminUpdateModel(userId, modelId, updateModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} userId User ID
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateModelContacts(
      userId: string,
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .adminUpdateModelContacts(userId, modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel(
      createModelData?: CreateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .createModel(createModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel(modelId: string, options?: any): AxiosPromise<UserModel> {
      return localVarFp
        .deleteModel(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel(modelId: string, options?: any): AxiosPromise<UserModel> {
      return localVarFp
        .getModel(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModels(options?: any): AxiosPromise<UserModelList> {
      return localVarFp
        .listModels(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginModel(
      modelId: string,
      loginModelData?: LoginModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .loginModel(modelId, loginModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage(
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: any,
    ): AxiosPromise<MessageReport> {
      return localVarFp
        .sendMessage(modelId, sendMessageData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel(
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .updateModel(modelId, updateModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelContacts(
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .updateModelContacts(modelId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ModelsApi - object-oriented interface
 * @export
 * @class ModelsApi
 * @extends {BaseAPI}
 */
export class ModelsApi extends BaseAPI {
  /**
   *
   * @summary Creates a new model.
   * @param {string} userId User ID
   * @param {CreateModelData} [createModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminCreateModel(
    userId: string,
    createModelData?: CreateModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminCreateModel(userId, createModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminDeleteModel(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminDeleteModel(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminGetModel(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminGetModel(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of models.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminListModels(userId: string, options?: AxiosRequestConfig) {
    return ModelsApiFp(this.configuration)
      .adminListModels(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates model token.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {LoginModelData} [loginModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminLoginModel(
    userId: string,
    modelId: string,
    loginModelData?: LoginModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminLoginModel(userId, modelId, loginModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send message as a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {SendMessageData} [sendMessageData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminSendMessage(
    userId: string,
    modelId: string,
    sendMessageData?: SendMessageData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminSendMessage(userId, modelId, sendMessageData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates a model.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {UpdateModelData} [updateModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminUpdateModel(
    userId: string,
    modelId: string,
    updateModelData?: UpdateModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminUpdateModel(userId, modelId, updateModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update model contacts.
   * @param {string} userId User ID
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public adminUpdateModelContacts(
    userId: string,
    modelId: string,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .adminUpdateModelContacts(userId, modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new model.
   * @param {CreateModelData} [createModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public createModel(
    createModelData?: CreateModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .createModel(createModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted a model.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public deleteModel(modelId: string, options?: AxiosRequestConfig) {
    return ModelsApiFp(this.configuration)
      .deleteModel(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a model.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public getModel(modelId: string, options?: AxiosRequestConfig) {
    return ModelsApiFp(this.configuration)
      .getModel(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of models.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public listModels(options?: AxiosRequestConfig) {
    return ModelsApiFp(this.configuration)
      .listModels(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates model token.
   * @param {string} modelId Model ID
   * @param {LoginModelData} [loginModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public loginModel(
    modelId: string,
    loginModelData?: LoginModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .loginModel(modelId, loginModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send message as a model.
   * @param {string} modelId Model ID
   * @param {SendMessageData} [sendMessageData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public sendMessage(
    modelId: string,
    sendMessageData?: SendMessageData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .sendMessage(modelId, sendMessageData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates a model.
   * @param {string} modelId Model ID
   * @param {UpdateModelData} [updateModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public updateModel(
    modelId: string,
    updateModelData?: UpdateModelData,
    options?: AxiosRequestConfig,
  ) {
    return ModelsApiFp(this.configuration)
      .updateModel(modelId, updateModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update model contacts.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModelsApi
   */
  public updateModelContacts(modelId: string, options?: AxiosRequestConfig) {
    return ModelsApiFp(this.configuration)
      .updateModelContacts(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCampaign: async (
      createCampaignData?: CreateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel: async (
      createModelData?: CreateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/models/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("deleteCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("deleteModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile: async (
      updateUserData?: UpdateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("getCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("getModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleLogin: async (
      googleLoginData?: GoogleLoginData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/google/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        googleLoginData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCampaigns: async (
      modelId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/campaigns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (modelId !== undefined) {
        localVarQueryParameter["modelId"] = modelId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModels: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/models/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      loginData?: LoginData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginModel: async (
      modelId: string,
      loginModelData?: LoginModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("loginModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/login`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage: async (
      modelId: string,
      sendMessageData?: SendMessageData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("sendMessage", "modelId", modelId);
      const localVarPath = `/models/{modelId}/message`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendMessageData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("startCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/start`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopCampaign: async (
      campaignId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("stopCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/stop`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCampaign: async (
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("updateCampaign", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCampaignData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel: async (
      modelId: string,
      updateModelData?: UpdateModelData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("updateModel", "modelId", modelId);
      const localVarPath = `/models/{modelId}/`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateModelData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelContacts: async (
      modelId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists("updateModelContacts", "modelId", modelId);
      const localVarPath = `/models/{modelId}/contacts`.replace(
        `{${"modelId"}}`,
        encodeURIComponent(String(modelId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCampaignMedia: async (
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'campaignId' is not null or undefined
      assertParamExists("uploadCampaignMedia", "campaignId", campaignId);
      const localVarPath = `/campaigns/{campaignId}/upload`.replace(
        `{${"campaignId"}}`,
        encodeURIComponent(String(campaignId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        uploadCampaignMediaData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCampaign(
      createCampaignData?: CreateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(
        createCampaignData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createModel(
      createModelData?: CreateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createModel(
        createModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteModel(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModel(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async editProfile(
      updateUserData?: UpdateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.editProfile(
        updateUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModel(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModel(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfile(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProfile(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googleLogin(
      googleLoginData?: GoogleLoginData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthData>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.googleLogin(
        googleLoginData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCampaigns(
      modelId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(
        modelId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listModels(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listModels(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginData?: LoginData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthData>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        loginData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loginModel(
      modelId: string,
      loginModelData?: LoginModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loginModel(
        modelId,
        loginModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendMessage(
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageReport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(
        modelId,
        sendMessageData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopCampaign(
      campaignId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopCampaign(
        campaignId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCampaign(
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(
        campaignId,
        updateCampaignData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModel(
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateModel(
        modelId,
        updateModelData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateModelContacts(
      modelId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateModelContacts(modelId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadCampaignMedia(
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadCampaignMedia(
          campaignId,
          uploadCampaignMediaData,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PublicApiFp(configuration);
  return {
    /**
     *
     * @summary Create a new campaign.
     * @param {CreateCampaignData} [createCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCampaign(
      createCampaignData?: CreateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .createCampaign(createCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a new model.
     * @param {CreateModelData} [createModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createModel(
      createModelData?: CreateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .createModel(createModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .deleteCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deleted a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteModel(modelId: string, options?: any): AxiosPromise<UserModel> {
      return localVarFp
        .deleteModel(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Edit user profile.
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    editProfile(
      updateUserData?: UpdateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .editProfile(updateUserData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get campaign details.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .getCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a model.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModel(modelId: string, options?: any): AxiosPromise<UserModel> {
      return localVarFp
        .getModel(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options?: any): AxiosPromise<User> {
      return localVarFp
        .getProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login with Google JWT.
     * @param {GoogleLoginData} [googleLoginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googleLogin(
      googleLoginData?: GoogleLoginData,
      options?: any,
    ): AxiosPromise<UserAuthData> {
      return localVarFp
        .googleLogin(googleLoginData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List model campaigns.
     * @param {string} [modelId] Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCampaigns(modelId?: string, options?: any): AxiosPromise<CampaignList> {
      return localVarFp
        .listCampaigns(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Returns a list of models.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listModels(options?: any): AxiosPromise<UserModelList> {
      return localVarFp
        .listModels(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login with password.
     * @param {LoginData} [loginData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginData?: LoginData, options?: any): AxiosPromise<UserAuthData> {
      return localVarFp
        .login(loginData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates model token.
     * @param {string} modelId Model ID
     * @param {LoginModelData} [loginModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loginModel(
      modelId: string,
      loginModelData?: LoginModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .loginModel(modelId, loginModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send message as a model.
     * @param {string} modelId Model ID
     * @param {SendMessageData} [sendMessageData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessage(
      modelId: string,
      sendMessageData?: SendMessageData,
      options?: any,
    ): AxiosPromise<MessageReport> {
      return localVarFp
        .sendMessage(modelId, sendMessageData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .startCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Stop campaign.
     * @param {string} campaignId Campaign ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopCampaign(campaignId: string, options?: any): AxiosPromise<Campaign> {
      return localVarFp
        .stopCampaign(campaignId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update campaign.
     * @param {string} campaignId Campaign ID
     * @param {UpdateCampaignData} [updateCampaignData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCampaign(
      campaignId: string,
      updateCampaignData?: UpdateCampaignData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .updateCampaign(campaignId, updateCampaignData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates a model.
     * @param {string} modelId Model ID
     * @param {UpdateModelData} [updateModelData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModel(
      modelId: string,
      updateModelData?: UpdateModelData,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .updateModel(modelId, updateModelData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update model contacts.
     * @param {string} modelId Model ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateModelContacts(
      modelId: string,
      options?: any,
    ): AxiosPromise<UserModel> {
      return localVarFp
        .updateModelContacts(modelId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload campaign media.
     * @param {string} campaignId Campaign ID
     * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCampaignMedia(
      campaignId: string,
      uploadCampaignMediaData?: UploadCampaignMediaData,
      options?: any,
    ): AxiosPromise<Campaign> {
      return localVarFp
        .uploadCampaignMedia(campaignId, uploadCampaignMediaData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
  /**
   *
   * @summary Create a new campaign.
   * @param {CreateCampaignData} [createCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public createCampaign(
    createCampaignData?: CreateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .createCampaign(createCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a new model.
   * @param {CreateModelData} [createModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public createModel(
    createModelData?: CreateModelData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .createModel(createModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public deleteCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .deleteCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deleted a model.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public deleteModel(modelId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .deleteModel(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Edit user profile.
   * @param {UpdateUserData} [updateUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public editProfile(
    updateUserData?: UpdateUserData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .editProfile(updateUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get campaign details.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public getCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .getCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a model.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public getModel(modelId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .getModel(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user profile.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public getProfile(options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .getProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login with Google JWT.
   * @param {GoogleLoginData} [googleLoginData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public googleLogin(
    googleLoginData?: GoogleLoginData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .googleLogin(googleLoginData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List model campaigns.
   * @param {string} [modelId] Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public listCampaigns(modelId?: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .listCampaigns(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns a list of models.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public listModels(options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .listModels(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login with password.
   * @param {LoginData} [loginData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public login(loginData?: LoginData, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .login(loginData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates model token.
   * @param {string} modelId Model ID
   * @param {LoginModelData} [loginModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public loginModel(
    modelId: string,
    loginModelData?: LoginModelData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .loginModel(modelId, loginModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send message as a model.
   * @param {string} modelId Model ID
   * @param {SendMessageData} [sendMessageData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public sendMessage(
    modelId: string,
    sendMessageData?: SendMessageData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .sendMessage(modelId, sendMessageData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public startCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .startCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Stop campaign.
   * @param {string} campaignId Campaign ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public stopCampaign(campaignId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .stopCampaign(campaignId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update campaign.
   * @param {string} campaignId Campaign ID
   * @param {UpdateCampaignData} [updateCampaignData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public updateCampaign(
    campaignId: string,
    updateCampaignData?: UpdateCampaignData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .updateCampaign(campaignId, updateCampaignData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates a model.
   * @param {string} modelId Model ID
   * @param {UpdateModelData} [updateModelData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public updateModel(
    modelId: string,
    updateModelData?: UpdateModelData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .updateModel(modelId, updateModelData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update model contacts.
   * @param {string} modelId Model ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public updateModelContacts(modelId: string, options?: AxiosRequestConfig) {
    return PublicApiFp(this.configuration)
      .updateModelContacts(modelId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload campaign media.
   * @param {string} campaignId Campaign ID
   * @param {UploadCampaignMediaData} [uploadCampaignMediaData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicApi
   */
  public uploadCampaignMedia(
    campaignId: string,
    uploadCampaignMediaData?: UploadCampaignMediaData,
    options?: AxiosRequestConfig,
  ) {
    return PublicApiFp(this.configuration)
      .uploadCampaignMedia(campaignId, uploadCampaignMediaData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateUser: async (
      createUserData?: CreateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteUser: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminDeleteUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetUser: async (
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminGetUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListUsers: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateUser: async (
      userId: string,
      updateUserData?: UpdateUserData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("adminUpdateUser", "userId", userId);
      const localVarPath = `/admin/users/{userId}/`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminCreateUser(
      createUserData?: CreateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUser(
        createUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDeleteUser(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteUser(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminGetUser(
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetUser(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminListUsers(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminListUsers(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminUpdateUser(
      userId: string,
      updateUserData?: UpdateUserData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUser(
        userId,
        updateUserData,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Creates a new user.
     * @param {CreateUserData} [createUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminCreateUser(
      createUserData?: CreateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .adminCreateUser(createUserData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDeleteUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .adminDeleteUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user.
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminGetUser(userId: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .adminGetUser(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a list of users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminListUsers(options?: any): AxiosPromise<UserList> {
      return localVarFp
        .adminListUsers(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user.
     * @param {string} userId User ID
     * @param {UpdateUserData} [updateUserData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminUpdateUser(
      userId: string,
      updateUserData?: UpdateUserData,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .adminUpdateUser(userId, updateUserData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Creates a new user.
   * @param {CreateUserData} [createUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public adminCreateUser(
    createUserData?: CreateUserData,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .adminCreateUser(createUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete user.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public adminDeleteUser(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .adminDeleteUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user.
   * @param {string} userId User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public adminGetUser(userId: string, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .adminGetUser(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a list of users.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public adminListUsers(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .adminListUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update user.
   * @param {string} userId User ID
   * @param {UpdateUserData} [updateUserData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public adminUpdateUser(
    userId: string,
    updateUserData?: UpdateUserData,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .adminUpdateUser(userId, updateUserData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
