import { User, UserType } from "@/apiGen";

export const DUMMY_USER: User = {
  id: "0",
  email: "Loading...",
  name: "Loading...",
  credits: 0,
  type: UserType.Basic,
};

export function createDummies(count: number): Array<User> {
  return Array.from({ length: count }, (_, i) => ({
    ...DUMMY_USER,
    id: (-i - 1).toString(),
  }));
}
