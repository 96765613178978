<template>
  <v-checkbox v-model="value" :label="label" :disabled="!failedIds.length" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { inject } from "@/injection";
import { Mutation } from "@/store/mutations";

const store = inject("store");

const failedIds = computed(() => store.state.filters.failedIds);
const label = computed(() => {
  if (!failedIds.value.length) return "No failed messages to send (yet)";
  return `Send only ${failedIds.value.length} failed messages`;
});

const value = computed({
  get() {
    if (!failedIds.value.length) return false;
    return store.state.filters.failedOnly;
  },
  set(value) {
    store.commit(Mutation.UpdateFilters, { failedOnly: value });
  },
});

if (!failedIds.value.length && value.value) {
  value.value = false;
}
</script>
