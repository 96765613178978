<template>
  <v-card>
    <v-card-title>Add new user</v-card-title>
    <v-card-text>
      <user-create-form @submit="onSubmit" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { router, ADMIN_ROUTES } from "@/router";
import UserCreateForm from "@/components/user/CreateForm.vue";
import { User } from "@/apiGen";

async function onSubmit(user: User) {
  router.push({
    name: ADMIN_ROUTES.USER_VIEW,
    params: { userId: user.id },
  });
}
</script>
