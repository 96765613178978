<template>
  <v-card>
    <v-card-title>Model {{ model.name }}</v-card-title>
    <v-card-text>
      <p>Name: {{ model.name }}</p>
      <p>Email: {{ model.email }}</p>
      <p>Contacts: {{ model.contacts.length }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="info"
        :to="{
          name: ROUTES.MODEL_EDIT,
          params: { modelId: model.id },
        }"
      >
        Edit
      </v-btn>
      <v-btn
        color="info"
        :to="{
          name: ROUTES.MODEL_LOGIN,
          params: { modelId: model.id },
        }"
      >
        Update password
      </v-btn>
      <v-btn
        color="info"
        :to="{
          name: ROUTES.MODEL_CAMPAIGN_LIST,
          params: { modelId: model.id },
        }"
      >
        Campaigns
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { inject } from "@/injection";

const model = inject("model");
const ROUTES = inject("routes");
</script>
