import UserListView from "@/views/admin/user/ListView.vue";
import UserCreateView from "@/views/admin/user/CreateView.vue";
import UserItemView from "@/views/admin/user/ItemView.vue";
import UserEditView from "@/views/admin/user/EditView.vue";
import UserWrapperView from "@/views/admin/user/WrapperView.vue";
import AdminView from "@/views/admin/AdminView.vue";
import ModelListView from "@/views/model/ListView.vue";
import ModelAddView from "@/views/model/AddView.vue";
import ModelMessengerView from "@/views/model/ModelMessengerView.vue";
import ModelLoginView from "@/views/model/ModelLoginView.vue";
import ModelEditView from "@/views/model/EditView.vue";
import CampaignListView from "@/views/campaign/ListView.vue";
import ModelCampaignListView from "@/views/campaign/ModelListView.vue";
import ModelCampaignAddView from "@/views/campaign/AddView.vue";
import ModelCampaignItemView from "@/views/campaign/ItemView.vue";
import ModelCampaignWrapperView from "@/views/campaign/WrapperView.vue";
import ModelWrapperView from "@/views/model/WrapperView.vue";
import ModelItemView from "@/views/model/ItemView.vue";
import type { RouteRecordRaw } from "vue-router";
import type { RouteMeta } from "@/types";

export const ADMIN_ROUTES = {
  HOME: "ADMIN_HOME",
  USERS: "ADMIN_USERS",
  USER_ADD: "ADMIN_USER_ADD",
  USER_VIEW: "ADMIN_USER_VIEW",
  USER_EDIT: "ADMIN_USER_EDIT",
  MODEL_LIST: "ADMIN_MODEL_LIST",
  MODEL_ADD: "ADMIN_MODEL_ADD",
  MODEL_VIEW: "ADMIN_MODEL_VIEW",
  MODEL_LOGIN: "ADMIN_MODEL_LOGIN",
  MODEL_EDIT: "ADMIN_MODEL_EDIT",
  MODEL_MESSENGER: "ADMIN_MODEL_MESSENGER",
  CAMPAIGN_WRAPPER_VIEW: "ADMIN_CAMPAIGN_WRAPPER_VIEW",
  CAMPAIGN_ADD: "ADMIN_CAMPAIGN_ADD",
  CAMPAIGN_LIST: "ADMIN_CAMPAIGN_LIST",
  MODEL_CAMPAIGN_LIST: "ADMIN_MODEL_CAMPAIGN_LIST",
  CAMPAIGN_VIEW: "ADMIN_CAMPAIGN_VIEW",
};

export const adminRoutes: RouteRecordRaw[] = [
  {
    name: ADMIN_ROUTES.HOME,
    path: "/admin",
    component: AdminView,
    meta: {
      title: "Admin",
      breadcrumbs: [{ title: "Admin", link: { name: ADMIN_ROUTES.HOME } }],
      admin: true,
    } as RouteMeta,
    children: [
      {
        path: "users",
        meta: {
          title: "Users",
          breadcrumbs: [{ title: "Users", link: { name: ADMIN_ROUTES.USERS } }],
        } as RouteMeta,
        children: [
          {
            path: "",
            name: ADMIN_ROUTES.USERS,
            component: UserListView,
          },
          {
            path: "add",
            name: ADMIN_ROUTES.USER_ADD,
            component: UserCreateView,
            meta: {
              title: "Add new user",
              breadcrumbs: [{ title: "Add" }],
            } as RouteMeta,
          },
          {
            path: ":userId",
            component: UserWrapperView,
            meta: {
              titleFunc: (_, getters) => getters.selectedUser.name,
              breadcrumbs: [
                {
                  titleFunc: (_, getters) => getters.selectedUser.name,
                  linkFunc: (params) => {
                    return {
                      name: ADMIN_ROUTES.USER_VIEW,
                      params: { userId: params.userId || "0" },
                    };
                  },
                },
              ],
            } as RouteMeta,
            children: [
              {
                path: "",
                name: ADMIN_ROUTES.USER_VIEW,
                component: UserItemView,
              },
              {
                path: "edit",
                name: ADMIN_ROUTES.USER_EDIT,
                component: UserEditView,
                meta: {
                  requiresUser: true,
                  titleFunc: (_, getters) => {
                    return `Edit user ${getters.selectedUser.name}`;
                  },
                  breadcrumbs: [{ title: "Edit" }],
                } as RouteMeta,
              },
              {
                name: ADMIN_ROUTES.CAMPAIGN_LIST,
                path: "campaigns",
                component: CampaignListView,
                meta: {
                  title: "Recent campaigns",
                  breadcrumbs: [
                    {
                      title: "Campaigns",
                      link: { name: ADMIN_ROUTES.CAMPAIGN_LIST },
                    },
                  ],
                } as RouteMeta,
              },
              {
                path: "models",
                meta: {
                  title: "Models",
                  breadcrumbs: [
                    {
                      title: "Models",
                      linkFunc: (params) => {
                        return {
                          name: ADMIN_ROUTES.MODEL_LIST,
                          params: { userId: params.userId || "0" },
                        };
                      },
                    },
                  ],
                } as RouteMeta,
                children: [
                  {
                    name: ADMIN_ROUTES.MODEL_LIST,
                    path: "",
                    component: ModelListView,
                  },
                  {
                    name: ADMIN_ROUTES.MODEL_ADD,
                    path: "add",
                    component: ModelAddView,
                    meta: {
                      title: "Add new model",
                      breadcrumbs: [
                        {
                          title: "Add",
                          linkFunc: (params) => {
                            return {
                              name: ADMIN_ROUTES.MODEL_ADD,
                              params: { userId: params.userId || "0" },
                            };
                          },
                        },
                      ],
                    } as RouteMeta,
                  },
                  {
                    path: ":modelId",
                    component: ModelWrapperView,
                    meta: {
                      titleFunc: (_, getters) => getters.selectedModel.name,
                      breadcrumbs: [
                        {
                          titleFunc: (_, getters) => getters.selectedModel.name,
                          linkFunc: (params) => {
                            return {
                              name: ADMIN_ROUTES.MODEL_VIEW,
                              params: {
                                userId: params.userId || "0",
                                modelId: params.modelId || "0",
                              },
                            };
                          },
                        },
                      ],
                    } as RouteMeta,
                    children: [
                      {
                        name: ADMIN_ROUTES.MODEL_VIEW,
                        path: "",
                        component: ModelItemView,
                      },
                      {
                        name: ADMIN_ROUTES.MODEL_EDIT,
                        path: "edit",
                        component: ModelEditView,
                        meta: {
                          requiresModel: true,
                          titleFunc: (_, getters) => {
                            return `Edit model ${getters.selectedModel.name}`;
                          },
                          breadcrumbs: [{ title: "Edit" }],
                        } as RouteMeta,
                      },
                      {
                        name: ADMIN_ROUTES.MODEL_LOGIN,
                        path: "password",
                        component: ModelLoginView,
                        meta: {
                          requiresModel: true,
                          title: "Change password",
                          breadcrumbs: [{ title: "Password" }],
                        } as RouteMeta,
                      },
                      {
                        name: ADMIN_ROUTES.MODEL_MESSENGER,
                        path: "messenger",
                        component: ModelMessengerView,
                        meta: {
                          title: "Messenger",
                          breadcrumbs: [{ title: "Messenger" }],
                        } as RouteMeta,
                      },
                      {
                        path: "campaigns",
                        meta: {
                          title: "Campaigns",
                          breadcrumbs: [
                            {
                              title: "Campaigns",
                              linkFunc: (params) => {
                                return {
                                  name: ADMIN_ROUTES.MODEL_CAMPAIGN_LIST,
                                  params: {
                                    userId: params.userId || "0",
                                    modelId: params.modelId || "0",
                                  },
                                };
                              },
                            },
                          ],
                        } as RouteMeta,
                        children: [
                          {
                            path: "",
                            name: ADMIN_ROUTES.MODEL_CAMPAIGN_LIST,
                            component: ModelCampaignListView,
                          },
                          {
                            name: ADMIN_ROUTES.CAMPAIGN_ADD,
                            path: "add",
                            component: ModelCampaignAddView,
                            meta: {
                              title: "Add new campaign",
                              breadcrumbs: [{ title: "Add" }],
                            } as RouteMeta,
                          },
                          {
                            name: ADMIN_ROUTES.CAMPAIGN_WRAPPER_VIEW,
                            path: ":campaignId",
                            component: ModelCampaignWrapperView,
                            meta: {
                              titleFunc: (_, getters) =>
                                getters.selectedCampaign.name,
                              breadcrumbs: [
                                {
                                  titleFunc: (_, getters) =>
                                    `Campaign ${getters.selectedCampaign.name}`,
                                  linkFunc: (params) => {
                                    return {
                                      name: ADMIN_ROUTES.CAMPAIGN_LIST,
                                      params: {
                                        userId: params.userId || "0",
                                        modelId: params.modelId || "0",
                                        campaignId: params.campaignId || "0",
                                      },
                                    };
                                  },
                                },
                              ],
                            } as RouteMeta,
                            children: [
                              {
                                name: ADMIN_ROUTES.CAMPAIGN_VIEW,
                                path: "",
                                component: ModelCampaignItemView,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
