<template>
  <v-card>
    <v-card-title>New model</v-card-title>
    <v-card-text>
      <model-create-form @submit="onSubmit" />
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { router } from "@/router";
import ModelCreateForm from "@/components/ModelCreateForm.vue";

function onSubmit() {
  router.replace(".");
}
</script>
