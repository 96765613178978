import type { IRoutes } from "./router/types";
import type { PublicApi, AdminApi } from "@/apiClient";
import { inject as vueInject, provide as vueProvide, Ref } from "vue";
import type { InjectionKey } from "vue";
import type { Store } from "@/store";
import type { Router } from "@/router";
import type { Campaign, User, UserModel } from "@/apiGen";

export const KEYS = {
  store: Symbol() as InjectionKey<Store>,
  router: Symbol() as InjectionKey<Router>,
  user: Symbol() as InjectionKey<Ref<User>>,
  model: Symbol() as InjectionKey<Ref<UserModel>>,
  campaign: Symbol() as InjectionKey<Ref<Campaign>>,
  api: Symbol() as InjectionKey<PublicApi>,
  adminApi: Symbol() as InjectionKey<AdminApi>,
  routes: Symbol() as InjectionKey<IRoutes>,
};

type Keys = typeof KEYS;
type Key = keyof Keys;
type extractType<Type> = Type extends InjectionKey<infer X> ? X : never;
type nullifyRef<Type> = Type extends Ref<infer X> ? Ref<X | null> : Type;

export function inject<V extends Key, T extends extractType<Keys[V]>>(
  key: V,
): T {
  const result = vueInject<T>(KEYS[key]);
  if (!result) throw new Error(`Cannot inject "${key}", provide it first`);
  return result;
}

export function provide<
  V extends Key,
  T extends nullifyRef<extractType<Keys[V]>>,
>(key: V, value: T): void {
  vueProvide<T>(KEYS[key], value);
}
