<template>
  <v-select label="Loyalty level" v-model="values" :items="choices" multiple />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { inject } from "@/injection";
import { Contact } from "@/apiGen";
import { Mutation } from "@/store/mutations";

interface IProps {
  contacts: Contact[];
}

const props = defineProps<IProps>();

const store = inject("store");

const choices = computed(() => {
  const result = Array.from(
    new Set(props.contacts.map((i) => i.loyalty_level)),
  ).filter((x) => x);
  result.sort((a, b) => a.localeCompare(b));
  return result;
});

const values = computed({
  get() {
    return store.state.filters.loyaltyLevels;
  },
  set(value) {
    store.commit(Mutation.UpdateFilters, { loyaltyLevels: value });
  },
});
</script>
