<template>
  <v-card>
    <v-card-title>User {{ user.name }}</v-card-title>
    <v-card-text>
      <p>Name: {{ user.name }}</p>
      <p>Email: {{ user.email }}</p>
      <p>Credits: {{ user.credits }}</p>
      <p>Type: {{ user.type }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="info"
        :to="{
          name: ADMIN_ROUTES.USER_EDIT,
          params: { userId: user.id },
        }"
      >
        Edit
      </v-btn>
      <v-btn
        color="info"
        :to="{
          name: ADMIN_ROUTES.MODEL_LIST,
          params: { userId: user.id },
        }"
      >
        Models
      </v-btn>
      <v-btn
        color="info"
        :to="{
          name: ADMIN_ROUTES.CAMPAIGN_LIST,
          params: { userId: user.id },
        }"
      >
        Campaigns
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { inject } from "@/injection";
import { ADMIN_ROUTES } from "@/router";

const user = inject("user");
</script>
