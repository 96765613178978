import { UserAuthData, ContactSorting, UserModel, Campaign } from "@/apiGen";
import { DUMMY_CAMPAIGN } from "@/models/campaign";
import { DUMMY_USER_MODEL } from "@/models/model";
import { DUMMY_USER_DATA } from "@/models/userData";
import type { Filters, Counters } from "./types";
import { User } from "@sentry/vue";
import { DUMMY_USER } from "@/models/user";

export type State = {
  userData: UserAuthData;
  selectedUser: User;
  selectedModel: UserModel;
  selectedCampaign: Campaign;
  filters: Filters;
  counters: Counters;
};

export const state: State = {
  userData: DUMMY_USER_DATA,
  selectedUser: DUMMY_USER,
  selectedModel: DUMMY_USER_MODEL,
  selectedCampaign: DUMMY_CAMPAIGN,
  filters: {
    loyaltyLevels: [],
    sorting: ContactSorting.LastMessageAtDesc,
    limit: null,
    failedIds: [],
    failedOnly: false,
  },
  counters: {
    models: -1,
    campaigns: -1,
    users: -1,
  },
};
