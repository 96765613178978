<template>
  <v-card>
    <v-layout justify-center>
      <v-progress-linear
        :model-value="sentPercent"
        color="light-blue"
        height="10"
        :striped="campaign.status == CampaignStatus.InProgress"
      ></v-progress-linear>
    </v-layout>
    <v-card-title>Campaign {{ campaign.name }}</v-card-title>
    <v-card-text>
      <p>Status: {{ campaign.status }}</p>
      <p>Status note: {{ campaign.status_note }}</p>
      <v-divider></v-divider>
      <p>Message: {{ campaign.message.text || "-" }}</p>
      <div v-if="campaign.message.file_name">
        <p>
          Media: {{ campaign.message.file_name }} (
          {{ getReadableFileSize(campaign.message.file_size) }})
        </p>
        <p>Price: {{ campaign.message.price || "free" }}</p>
      </div>
      <v-divider></v-divider>
      <p>Contacts: {{ campaign.contacts.length }}</p>
      <p>Sent {{ campaign.result.success.length }} messages</p>
      <p>Failed to send {{ campaign.result.failed.length }} messages</p>
      <p>Progress: {{ sentPercent }}%</p>
      <p>Cost: {{ campaign.message.credits_cost }} credits</p>
      <v-divider></v-divider>
      <p>Created: {{ fromNow(campaign.created_at) }}</p>
    </v-card-text>
    <v-card-actions>
      <async-button
        :disabled="loading"
        :onClick="startCampaign"
        v-if="
          campaign.status === CampaignStatus.Created ||
          campaign.status === CampaignStatus.Stopped
        "
        color="info"
      >
        {{ campaign.status === CampaignStatus.Created ? "Start" : "Resume" }}
      </async-button>
      <async-button
        :disabled="loading"
        :onClick="stopCampaign"
        v-if="campaign.status === CampaignStatus.InProgress"
        color="warning"
      >
        Stop
      </async-button>
      <async-button
        :disabled="loading"
        :onClick="deleteCampaign"
        v-if="campaign.status !== CampaignStatus.InProgress"
        color="error"
        confirm="Are you sure you want to delete this campaign?"
      >
        Delete
      </async-button>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { computed, onBeforeUnmount } from "vue";
import { inject } from "@/injection";
import { CampaignStatus } from "@/apiGen";
import AsyncInterval from "@/asyncInterval";
import AsyncButton from "@/components/AsyncButton.vue";
import { router } from "@/router";
import { getReadableFileSize, fromNow } from "@/utils";

const api = inject("api");
const campaign = inject("campaign");

interface IProps {
  loading?: boolean;
}

defineProps<IProps>();

const sentPercent = computed(() => {
  const totalSent =
    campaign.value.result.success.length + campaign.value.result.failed.length;
  return Math.round((totalSent / campaign.value.contacts.length) * 100);
});

async function startCampaign() {
  const response = await api.startCampaign(campaign.value.id);
  campaign.value = response.data;
  checkStatusInterval.start();
}

async function stopCampaign() {
  const response = await api.stopCampaign(campaign.value.id);
  campaign.value = response.data;
  checkStatusInterval.stop();
}

async function deleteCampaign() {
  await api.deleteCampaign(campaign.value.id);
  checkStatusInterval.stop();
  router.replace(".");
}

const checkStatusInterval = new AsyncInterval(
  (runner) => {
    if (!campaign.value.id) return;
    if (campaign.value.status !== CampaignStatus.InProgress) return;
    return api
      .getCampaign(campaign.value.id)
      .then((response) => {
        campaign.value = response.data;
        if (campaign.value.status !== CampaignStatus.InProgress) {
          runner.stop();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  { timeout: 2000, start: true },
);
onBeforeUnmount(() => {
  checkStatusInterval.stop();
});
</script>
