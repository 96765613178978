<template>
  <v-text-field
    v-model="value"
    label="Limit max contacts"
    type="number"
    autocomplete="off"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { inject } from "@/injection";
import { Mutation } from "@/store/mutations";

const store = inject("store");

const value = computed({
  get() {
    return store.state.filters.limit;
  },
  set(value) {
    store.commit(Mutation.UpdateFilters, { limit: value || null });
  },
});
</script>
