<template>
  <v-card :loading="loading">
    <v-card-title>{{ model.name }}</v-card-title>
    <v-card-text>
      <p>Email: {{ model.email }}</p>
      <p>Contacts: {{ model.contacts_count }}</p>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" @click="viewModel" :disabled="loading">View</v-btn>
      <v-btn
        color="info"
        :disabled="loading"
        :to="{
          name: ROUTES.MODEL_CAMPAIGN_LIST,
          params: { modelId: model.id },
        }"
      >
        Campaigns
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { inject } from "@/injection";
import { UserModelShort } from "@/apiGen";
import { Mutation } from "@/store/mutations";
import { enrichUserModel } from "@/models/model";

interface IProps {
  model: UserModelShort;
  loading?: boolean;
}

const ROUTES = inject("routes");
const store = inject("store");
const router = inject("router");

const { model } = defineProps<IProps>();

function viewModel() {
  store.commit(Mutation.SetSelectedModel, enrichUserModel(model));
  router.push({
    name: ROUTES.MODEL_VIEW,
    params: { modelId: model.id },
  });
}
</script>
