import { createStore, Store as VuexStore, CommitOptions } from "vuex";
import { state, State } from "./state";
import { getters, ResolvedGetters } from "./getters";
import { mutations, Mutations, Mutation } from "./mutations";
import { USER_DATA_KEY } from "@/constants";

const persistUserData = (store: Store) => {
  const savedUserDataStr = localStorage.getItem(USER_DATA_KEY);
  if (savedUserDataStr) {
    try {
      const savedUserData = JSON.parse(savedUserDataStr);
      store.commit(Mutation.SetUserData, savedUserData);
    } catch (e) {
      console.error(e);
    }
  }
  store.subscribe((mutation, state) => {
    if (mutation.type === Mutation.Logout) {
      localStorage.removeItem(USER_DATA_KEY);
    }
    if (mutation.type === Mutation.SetUserData) {
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(state.userData));
    }
  });
};

export const store = createStore({
  state,
  getters,
  mutations,
  plugins: [persistUserData],
});

export type Store = Omit<VuexStore<State>, "getters" | "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  getters: ResolvedGetters;
};

export function useStore() {
  return store as Store;
}
