interface IOptions {
  timeout: number;
  start?: boolean;
}

export default class AsyncInterval {
  timeout: number;
  running: symbol | null;

  constructor(
    private fn: (runner: AsyncInterval) => Promise<void> | void,
    options: IOptions = { timeout: 1000 },
  ) {
    this.fn = fn;
    this.timeout = options.timeout;
    this.running = null;
    if (options.start) this.start();
  }

  async start(): Promise<boolean> {
    this.running = Symbol();
    const result = this.runDelayed(this.timeout, this.running);
    await this.fn(this);
    return result;
  }

  delayStart(timeout: number): Promise<boolean> {
    this.running = Symbol();
    return this.runDelayed(timeout, this.running);
  }

  private async runDelayed(
    timeout: number,
    checkRunning: symbol,
  ): Promise<boolean> {
    await new Promise<void>((resolve) => setTimeout(resolve, timeout));
    if (checkRunning !== this.running) return false;
    await this.fn(this);
    return this.runDelayed(this.timeout, checkRunning);
  }

  stop() {
    this.running = null;
  }
}
