<template>
  <v-col cols="6">
    <v-card>
      <v-card-title>Edit {{ user.email }} profile</v-card-title>
      <v-card-text>
        <v-form validate-on="submit" @submit.prevent="submit">
          <v-text-field
            v-model="formData.password"
            type="password"
            label="New password"
          ></v-text-field>
          <v-text-field
            v-model="formData.confirm"
            type="password"
            label="New password again"
            :rules="[
              (v) => {
                if (v === formData.password) return true;
                return 'Passwords do not match';
              },
            ]"
          />
          <v-btn elevated :loading="loading" color="info" type="submit">
            Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script setup lang="ts">
import { inject } from "@/injection";
import { useToast } from "vue-toast-notification";
import { ref, computed } from "vue";
import type { SubmitEventPromise } from "@/types";

const api = inject("api");
const store = inject("store");
const toast = useToast();

const user = computed(() => store.state.userData);

const loading = ref(false);
const formData = ref({
  password: "",
  confirm: "",
});

async function submit(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .editProfile({
      password: formData.value.password,
    })
    .then(() => {
      toast.success("Profile saved.");
    })
    .catch((e) => {
      console.error(e);
      // toast.error(error.message);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
