import type { IRouteParams } from "@/router/types";
import { GetterTree } from "vuex";
import { State } from "./state";
import {
  ContactSorting,
  type Contact,
  type UserModel,
  Campaign,
  UserAuthData,
  UserType,
  User,
} from "@/apiGen";
import { getCurrentRouteMeta, router } from "@/router";
import type { RouteMeta } from "@/types";
import { Counters } from "./types";

export type Getters = {
  userData(state: State): UserAuthData;
  isAuthenticated(state: State, getters: ResolvedGetters): boolean;
  isAdmin(state: State, getters: ResolvedGetters): boolean;
  contacts(state: State, getters: ResolvedGetters): Contact[];
  accessToken(state: State): string;
  currentRouteParams(state: State): IRouteParams;
  currentRouteMeta(state: State, getters: ResolvedGetters): RouteMeta;
  selectedContacts(state: State, getters: ResolvedGetters): Contact[];
  selectedUser(state: State, getters: ResolvedGetters): User;
  selectedModel(state: State, getters: ResolvedGetters): UserModel;
  selectedCampaign(state: State, getters: ResolvedGetters): Campaign;
  counters(state: State): Counters;
};

export type ResolvedGetters = {
  [K in keyof Getters]: ReturnType<Getters[K]>;
};

export const getters: GetterTree<State, State> & Getters = {
  userData: (state) => {
    return state.userData;
  },
  isAuthenticated: (_, getters) => {
    return !!getters.userData.token;
  },
  isAdmin: (_, getters) => {
    return getters.userData.type === UserType.Admin;
  },
  contacts: (_, getters) => {
    return getters.selectedModel.contacts;
  },
  accessToken: (state) => {
    return state.userData.token;
  },
  currentRouteParams: () => {
    return router.currentRoute.value.params;
  },
  selectedUser: (state, getters) => {
    const userId = getters.currentRouteParams.userId;
    return {
      ...state.selectedUser,
      id: userId,
    } as User;
  },
  selectedModel: (state, getters) => {
    const modelId = getters.currentRouteParams.modelId;
    return {
      ...state.selectedModel,
      id: modelId,
    } as UserModel;
  },
  selectedCampaign: (state, getters) => {
    const modelId = getters.currentRouteParams.modelId;
    const campaignId = getters.currentRouteParams.campaignId;
    return {
      ...state.selectedCampaign,
      id: campaignId,
      model_id: modelId,
    } as Campaign;
  },
  currentRouteMeta: (state, getters) => {
    const result = getCurrentRouteMeta();
    if (result.titleFunc) result.title = result.titleFunc(state, getters);
    for (const breadcrumb of result.breadcrumbs || []) {
      if (breadcrumb.titleFunc)
        breadcrumb.title = breadcrumb.titleFunc(state, getters);
      if (breadcrumb.linkFunc)
        breadcrumb.link = breadcrumb.linkFunc(getters.currentRouteParams);
    }
    return result;
  },
  selectedContacts: (state, getters) => {
    let contacts = getters.contacts;
    if (state.filters.loyaltyLevels.length) {
      contacts = contacts.filter((i) =>
        state.filters.loyaltyLevels.includes(i.loyalty_level),
      );
    }
    const sorting = state.filters.sorting;
    switch (sorting) {
      case ContactSorting.LastMessageAt:
        contacts.sort((a, b) => {
          if (a.last_message_at > b.last_message_at) return -1;
          if (a.last_message_at < b.last_message_at) return 1;
          return 0;
        });
        break;
      case ContactSorting.LastMessageAtDesc:
        contacts.sort((a, b) => {
          if (a.last_message_at < b.last_message_at) return -1;
          if (a.last_message_at > b.last_message_at) return 1;
          return 0;
        });
        break;
      case ContactSorting.Name:
        contacts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case ContactSorting.NameDesc:
        contacts.sort((a, b) => b.name.localeCompare(a.name));
        break;
    }

    const limit = state.filters.limit;
    if (limit) {
      contacts = contacts.slice(0, limit);
    }

    if (state.filters.failedOnly && state.filters.failedIds.length) {
      contacts = contacts.filter((c) => state.filters.failedIds.includes(c.id));
    }

    return contacts;
  },
  counters: (state) => {
    return {
      models: state.counters.models > 0 ? state.counters.models : 3,
      campaigns: state.counters.campaigns > 0 ? state.counters.campaigns : 3,
      users: state.counters.users > 0 ? state.counters.users : 3,
    };
  },
};
