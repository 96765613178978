<template>
  <v-form validate-on="submit" @submit.prevent="createCampaign">
    <v-row>
      <v-col>
        <v-text-field
          label="Name"
          v-model="formData.name"
          :rules="[
            (v) => {
              if (!v) return false;
              return true;
            },
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          :loading="contactsLoading"
          prepend-icon="mdi-view-list"
          elevated
          color="info"
          @click="getContacts"
        >
          Refresh contacts
        </v-btn>
        {{ selectedContacts.length }} of {{ model.contacts.length }} contacts selected
      </v-col>
    </v-row>
    <v-row v-if="model.contacts.length">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>
              Show {{ selectedContacts.length }} selected contacts
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <span
                v-for="(contact, index) in selectedContacts.slice(0, 1000)"
                :key="contact.id"
              >
                <span v-if="index !== 0">,</span>
                <a
                  target="_blank"
                  :href="`https://modelcenter.livejasmin.com/en/messenger/current-chats/${contact.id}`"
                >
                  {{ contact.name }}
                </a>
                ({{ fromNow(contact.last_message_at) }})
              </span>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <LoyaltyLevelFilter :contacts="model.contacts" />
      </v-col>
      <v-col cols="12" md="4">
        <SortingFilter />
      </v-col>
      <v-col cols="12" md="4">
        <LimitFilter />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-file-input
          label="File"
          accept="image/*,video/*"
          v-model="mediaFile"
        ></v-file-input>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          label="File price"
          v-model="formData.price"
          item-text="title"
          item-value="value"
          :items="PRICES"
        ></v-select>
      </v-col>
      <v-col>
        <v-textarea
          label="Message"
          v-model="formData.text"
          :rules="[
            (v) => {
              if (v) return true;
              if (!mediaFile) return 'Message or file is required';
              return true;
            },
          ]"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn elevated :loading="loading" color="success" type="submit" class="mx-2">
          Add new campaign
        </v-btn>
        <v-btn to=".">Cancel</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import LoyaltyLevelFilter from "./filters/LoyaltyLevelFilter.vue";
import SortingFilter from "./filters/SortingFilter.vue";
import LimitFilter from "./filters/LimitFilter.vue";
import type { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";
import { PRICES } from "@/constants";
import dayjs from "@/dayjs";
import { Campaign, ContactSorting, CreateCampaignData } from "@/apiGen";
import { useToast } from "vue-toast-notification";
import { Mutation } from "@/store/mutations";
import { fromNow } from "@/utils";

const api = inject("api");
const model = inject("model");
const store = inject("store");

const selectedContacts = computed(() => {
  return store.getters.selectedContacts;
});

const emit = defineEmits<(event: "submit", model: Campaign) => void>();

const formData = ref<CreateCampaignData>({
  name: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  model_id: model.value.id,
  contacts: [],
  settings: {
    loyalty_levels: [],
    sorting: ContactSorting.LastMessageAtDesc,
    limit: 100,
  },
  price: 0,
  text: "",
});
const mediaFile = ref<File | undefined>(undefined);

const loading = ref(false);
const contactsLoading = ref(false);
const toast = useToast();

async function createCampaign(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  if (!selectedContacts.value.length) {
    toast.error("No contacts selected.");
    return;
  }
  loading.value = true;

  try {
    const createResponse = await api.createCampaign({
      ...formData.value,
      contacts: selectedContacts.value,
    });
    let campaign = createResponse.data;
    if (mediaFile.value) {
      const uploadResponse = await api.uploadCampaignMedia(createResponse.data.id, {
        file: mediaFile.value,
      });
      campaign = uploadResponse.data;
    }
    emit("submit", campaign);
  } catch (e) {
    loading.value = false;
    console.log(e);
    return;
  }

  loading.value = false;
}

async function getContacts() {
  contactsLoading.value = true;
  return api
    .updateModelContacts(model.value.id)
    .then((response) => {
      model.value = response.data;
      store.commit(Mutation.SetSelectedModel, model.value);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      contactsLoading.value = false;
    });
}
</script>
