<template>
  <v-breadcrumbs v-if="items.length" :items="items">
    <template v-slot:divider>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script setup lang="ts">
import { inject } from "@/injection";
import { computed } from "vue";
import { RouteLocationRaw } from "vue-router";

const store = inject("store");

const items = computed<{ title: string; to?: RouteLocationRaw }[]>(() => {
  const routeMeta = store.getters.currentRouteMeta;
  const breadcrumbs = routeMeta.breadcrumbs || [];
  return breadcrumbs.map((breadcrumb) => ({
    title: breadcrumb.title || "",
    to: breadcrumb.link,
  }));
});
</script>
