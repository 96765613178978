import { createApp } from "vue";
import App from "./App.vue";
import { store } from "./store";
import useSentry from "./sentry";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { router } from "./router";
import GoogleSignInPlugin from "vue3-google-signin";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";

loadFonts();

const app = createApp(App);

useSentry(app);

app.use(store);
app.use(vuetify);
app.use(router);
app.use(ToastPlugin);

app.use(GoogleSignInPlugin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

app.mount("#app");
