<template>
  <v-row>
    <v-col>
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component
            :key="route.path"
            :is="Component"
            v-if="!loading || !route.meta.requiresCampaign"
            :loading="loading"
          />
        </transition>
      </router-view>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { inject, provide } from "@/injection";
import { Campaign } from "@/apiGen";
import { Mutation } from "@/store/mutations";
import { ROUTES, router } from "@/router";

const api = inject("api");
const store = inject("store");
const loading = ref(true);
const campaign = computed<Campaign>({
  get: () => {
    return store.getters.selectedCampaign;
  },
  set: (value) => {
    store.commit(Mutation.SetSelectedCampaign, value);
  },
});

const model = inject("model");
provide("campaign", campaign);

api
  .getCampaign(campaign.value.id)
  .then((response) => {
    campaign.value = response.data;
  })
  .catch((e) => {
    console.error(e);
    router.push({
      name: ROUTES.MODEL_CAMPAIGN_LIST,
      params: { modelId: model.value.id },
    });
  })
  .finally(() => {
    loading.value = false;
  });
</script>
