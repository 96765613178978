import { UserAuthData, UserType } from "@/apiGen";

export const DUMMY_USER_DATA: UserAuthData = {
  id: "0",
  token: "",
  email: "",
  credits: 0,
  name: "",
  type: UserType.Basic,
};
