<template>
  <v-row>
    <v-col cols="12">
      <v-form validate-on="submit" @submit.prevent="loginModel">
        <v-text-field v-model="model.email" disabled label="Email" />
        <v-text-field
          v-model="formData.password"
          label="Password"
          type="password"
          autocomplete="new-password"
          required
          :rules="[(v) => !!v || 'Password is required']"
        />
        <v-btn
          elevated
          :loading="loading"
          type="submit"
          color="info"
          class="mx-2"
        >
          Save
        </v-btn>
        <v-btn :to="{ name: ROUTES.MODEL_VIEW, params: { modelId: model.id } }">
          Cancel
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { ROUTES } from "@/router";
import { UserModel } from "@/apiGen";
import { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";

const model = inject("model");
const api = inject("api");

const emit = defineEmits<(event: "submit", model: UserModel) => void>();

const formData = ref({
  password: "",
});
const loading = ref(false);

async function loginModel(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .loginModel(model.value.id, {
      password: formData.value.password,
    })
    .then((response) => {
      emit("submit", response.data);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
