<template>
  <v-form validate-on="submit" @submit.prevent="createModel">
    <v-text-field
      v-model="formData.email"
      label="Email"
      type="email"
      required
      autocomplete="off"
      :rules="[
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'Invalid Email address',
      ]"
    />
    <v-text-field
      v-model="formData.password"
      label="Password"
      type="password"
      autocomplete="new-password"
      required
      :rules="[(v) => !!v || 'Password is required']"
    />
    <v-btn
      elevated
      :loading="loading"
      color="success"
      type="submit"
      class="mx-2"
    >
      Add new model
    </v-btn>
    <v-btn to=".">Cancel</v-btn>
  </v-form>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { UserModel, CreateModelData } from "@/apiGen";
import type { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";

const api = inject("api");

const emit = defineEmits<(event: "submit", model: UserModel) => void>();

const formData = ref<CreateModelData>({
  email: "",
  password: "",
});

const loading = ref(false);

async function createModel(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  return api
    .createModel(formData.value)
    .then((response) => {
      emit("submit", response.data);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
