<template>
  <router-view v-slot="{ Component, route }">
    <transition name="fade">
      <component
        v-if="!loading || !route.meta.requiresModel"
        :key="route.path"
        :is="Component"
        :loading="loading"
      />
    </transition>
  </router-view>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { inject, provide } from "@/injection";
import { UserModel } from "@/apiGen";
import { Mutation } from "@/store/mutations";
import { ROUTES, router } from "@/router";

const loading = ref(true);

const store = inject("store");
const api = inject("api");
const model = computed<UserModel>({
  get: () => {
    return store.getters.selectedModel;
  },
  set: (value) => {
    store.commit(Mutation.SetSelectedModel, value);
  },
});
provide("model", model);

api
  .getModel(model.value.id)
  .then((response) => {
    model.value = response.data;
  })
  .catch((e) => {
    console.error(e);
    router.push({ name: ROUTES.MODEL_LIST });
  })
  .finally(() => {
    loading.value = false;
  });
</script>
