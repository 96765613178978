<template>
  <v-form validate-on="submit" @submit.prevent="sendMessages">
    <v-container>
      <v-row v-if="error">
        <v-col>
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="9">
          <v-file-input
            label="File"
            accept="image/*,video/*"
            v-model="formData.files"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            label="File price"
            v-model="formData.price"
            item-text="title"
            item-value="value"
            :items="PRICES"
          ></v-select>
        </v-col>
        <v-col>
          <v-textarea
            label="Message"
            v-model="formData.message"
            :rules="[
              (v) => {
                if (v) return true;
                if (!formData.files?.length)
                  return 'Message or file is required';
                return true;
              },
            ]"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-btn
            v-if="!loading"
            prepend-icon="mdi-send"
            elevated
            :disabled="!selectedContacts.length"
            color="info"
            type="submit"
          >
            Send {{ selectedContacts.length }} messages
          </v-btn>
          <v-btn v-if="loading" elevated color="info" @click="pause">
            {{ paused ? "Resume" : "Pause" }}
          </v-btn>
          <v-btn v-if="loading" elevated color="error" @click="cancel">
            Cancel
          </v-btn>
        </v-col>
        <v-col v-if="sentMessages" md="3">
          Sent {{ sentMessages }} messages
          <v-progress-linear
            :model-value="sentPercent"
            striped
          ></v-progress-linear>
        </v-col>
        <v-col v-if="failedMessages" md="3">
          Failed {{ failedMessages }} messages
          <v-progress-linear
            :model-value="failedPercent"
            color="deep-orange"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { chunkify } from "@/utils";
import type { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";
import { Contact } from "@/apiGen";
import { PRICES } from "@/constants";
import { Mutation } from "@/store/mutations";

const api = inject("api");
const model = inject("model");
const store = inject("store");

const paused = ref(false);
const canceled = ref(false);
const loading = ref(false);
const error = ref("");
const sentMessages = ref(0);
const failedMessages = ref(0);

const formData = ref({
  files: undefined as File[] | undefined,
  price: 0,
  message: "",
});

const selectedContacts = computed(() => {
  return store.getters.selectedContacts;
});
const messageContacts = ref<Contact[]>([]);

const selectedFile = computed<File | undefined>(() => {
  if (!formData.value.files) return undefined;
  return formData.value.files.length ? formData.value.files[0] : undefined;
});

const sentPercent = computed(() => {
  return (sentMessages.value / messageContacts.value.length) * 100;
});

const failedPercent = computed(() => {
  return (failedMessages.value / messageContacts.value.length) * 100;
});

const failedIds = computed<number[]>({
  get: () => {
    return store.state.filters.failedIds;
  },
  set: (value: number[]) => {
    store.commit(Mutation.UpdateFilters, { failedIds: value });
  },
});

function pause(): void {
  paused.value = !paused.value;
}

function cancel(): void {
  paused.value = false;
  canceled.value = true;
}

async function sendMessages(event: SubmitEventPromise): Promise<void> {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;
  paused.value = false;
  canceled.value = false;
  error.value = "";
  sentMessages.value = 0;
  failedMessages.value = 0;
  const messagedIds: number[] = [];
  messageContacts.value = [...selectedContacts.value];

  const chunks = chunkify(messageContacts.value, 5);
  try {
    for (const chunk of chunks) {
      if (canceled.value) break;

      if (paused.value) {
        await new Promise<void>((resolve) =>
          setInterval(() => {
            if (!paused.value) resolve();
          }, 1000),
        );
      }
      const response = await api.sendMessage(model.value.id, {
        ids: chunk.map((c) => c.id),
        file: selectedFile.value,
        price: formData.value.price,
        message: formData.value.message,
      });
      sentMessages.value += response.data.sent.length;
      failedMessages.value += response.data.failed.length;
      const chunkMessagedContacts = chunk.filter((c) =>
        response.data.sent.includes(c.id),
      );
      for (const contact of chunkMessagedContacts) {
        messagedIds.push(contact.id);
      }
    }
  } finally {
    loading.value = false;
  }

  failedIds.value = selectedContacts.value
    .filter((c) => !messagedIds.includes(c.id))
    .map((c) => c.id);
  failedMessages.value = failedIds.value.length;
}
</script>
