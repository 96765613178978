<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          color="success"
          :to="{ name: ROUTES.CAMPAIGN_ADD, params: { modelId: model.id } }"
        >
          Add new campaign
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="campaigns.length === 0">
      <v-col>
        No campaigns yet,
        <router-link
          :to="{ name: ROUTES.CAMPAIGN_ADD, params: { modelId: model.id } }"
        >
          start a new one
        </router-link>
        !
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="4" v-for="campaign in campaigns" :key="campaign.id">
        <campaign-list-item
          :campaign="campaign"
          :loading="loading"
          @changed="() => checkStatusInterval.start()"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeUnmount } from "vue";
import { inject } from "@/injection";
import { CampaignShort, CampaignStatus } from "@/apiGen";
import AsyncInterval from "@/asyncInterval";
import { store } from "@/store";
import { Mutation } from "@/store/mutations";
import { createDummies } from "@/models/campaign";
import CampaignListItem from "@/components/campaign/ListItem.vue";

const api = inject("api");
const ROUTES = inject("routes");

const loading = ref(true);
const campaigns = ref<CampaignShort[]>(
  createDummies(store.getters.counters.campaigns),
);

const model = inject("model");

const inProgressCampaigns = computed(() => {
  return campaigns.value.filter(
    (campaign) => campaign.status === CampaignStatus.InProgress,
  );
});

const checkStatusInterval = new AsyncInterval(
  (runner) => {
    if (!model.value.id) return;

    return api
      .listCampaigns(model.value.id)
      .then((response) => {
        campaigns.value = response.data.campaigns;
        store.commit(Mutation.SetCounters, {
          campaigns: campaigns.value.length,
        });
        if (!inProgressCampaigns.value.length) {
          runner.delayStart(10000);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        loading.value = false;
      });
  },
  { timeout: 2000, start: true },
);
onBeforeUnmount(() => {
  checkStatusInterval.stop();
});
</script>
