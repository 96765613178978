<template>
  <div>
    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component v-if="Component" :key="route.path" :is="Component" />
        <div v-else>
          <v-btn :to="{ name: ADMIN_ROUTES.USERS }">Users</v-btn>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { provide } from "@/injection";
import { getAdminApi } from "@/apiClient";
import { ROUTES, ADMIN_ROUTES } from "@/router";

const adminApi = getAdminApi();

provide("adminApi", adminApi);
provide("routes", {
  ...ROUTES,
  ...ADMIN_ROUTES,
});
</script>
