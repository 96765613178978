import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";
import DemoView from "@/views/DemoView.vue";
import ModelListView from "@/views/model/ListView.vue";
import ModelAddView from "@/views/model/AddView.vue";
import ModelMessengerView from "@/views/model/ModelMessengerView.vue";
import ModelLoginView from "@/views/model/ModelLoginView.vue";
import ModelEditView from "@/views/model/EditView.vue";
import CampaignListView from "@/views/campaign/ListView.vue";
import ModelCampaignListView from "@/views/campaign/ModelListView.vue";
import ModelCampaignAddView from "@/views/campaign/AddView.vue";
import ModelCampaignItemView from "@/views/campaign/ItemView.vue";
import ModelCampaignWrapperView from "@/views/campaign/WrapperView.vue";
import ModelWrapperView from "@/views/model/WrapperView.vue";
import ModelItemView from "@/views/model/ItemView.vue";
import HomeView from "@/views/HomeView.vue";
import { RouteRecordRaw } from "vue-router";
export { ADMIN_ROUTES } from "./admin";
import type { RouteMeta } from "@/types";
import type { IRoutes } from "./types";

export type { Router, RouteParams, RouteLocationRaw } from "vue-router";

export const ROUTES: IRoutes = {
  HOME: "HOME",
  LOGIN: "LOGIN",
  MODEL_LIST: "MODEL_LIST",
  MODEL_ADD: "MODEL_ADD",
  MODEL_VIEW: "MODEL_VIEW",
  MODEL_LOGIN: "MODEL_LOGIN",
  MODEL_EDIT: "MODEL_EDIT",
  MODEL_MESSENGER: "MODEL_MESSENGER",
  DEMO: "DEMO",
  PROFILE: "PROFILE",
  CAMPAIGN_WRAPPER_VIEW: "CAMPAIGN_WRAPPER_VIEW",
  CAMPAIGN_ADD: "CAMPAIGN_ADD",
  MODEL_CAMPAIGN_LIST: "MODEL_CAMPAIGN_LIST",
  CAMPAIGN_LIST: "CAMPAIGN_LIST",
  CAMPAIGN_VIEW: "CAMPAIGN_VIEW",
};

export const userRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.LOGIN,
    path: "/login",
    component: LoginView,
    meta: {
      title: "Login",
      breadcrumbs: [{ title: "Login", link: { name: ROUTES.LOGIN } }],
    } as RouteMeta,
  },
  {
    name: ROUTES.PROFILE,
    path: "/me",
    component: ProfileView,
    meta: {
      title: "My profile",
      breadcrumbs: [{ title: "Profile", link: { name: ROUTES.PROFILE } }],
    } as RouteMeta,
  },
  {
    name: ROUTES.HOME,
    path: "/",
    component: HomeView,
    meta: {
      title: "WebChat AI",
      breadcrumbs: [{ title: "Home", link: { name: ROUTES.HOME } }],
    } as RouteMeta,
    children: [
      {
        name: ROUTES.DEMO,
        path: "demo",
        component: DemoView,
        meta: {
          title: "Information",
          breadcrumbs: [{ title: "Information", link: { name: ROUTES.DEMO } }],
        } as RouteMeta,
      },
      {
        name: ROUTES.CAMPAIGN_LIST,
        path: "campaigns",
        component: CampaignListView,
        meta: {
          title: "Recent campaigns",
          breadcrumbs: [
            { title: "Campaigns", link: { name: ROUTES.CAMPAIGN_LIST } },
          ],
        } as RouteMeta,
      },
      {
        path: "models",
        meta: {
          title: "Models",
          breadcrumbs: [{ title: "Models", link: { name: ROUTES.MODEL_LIST } }],
        } as RouteMeta,
        children: [
          {
            name: ROUTES.MODEL_LIST,
            path: "",
            component: ModelListView,
          },
          {
            name: ROUTES.MODEL_ADD,
            path: "add",
            component: ModelAddView,
            meta: {
              title: "Add new model",
              breadcrumbs: [{ title: "Add", link: { name: ROUTES.MODEL_ADD } }],
            } as RouteMeta,
          },
          {
            path: ":modelId",
            component: ModelWrapperView,
            meta: {
              titleFunc: (_, getters) => getters.selectedModel.name,
              breadcrumbs: [
                {
                  titleFunc: (_, getters) => getters.selectedModel.name,
                  linkFunc: (params) => ({
                    name: ROUTES.MODEL_VIEW,
                    params: { modelId: params.modelId || "0" },
                  }),
                },
              ],
            } as RouteMeta,
            children: [
              {
                name: ROUTES.MODEL_VIEW,
                path: "",
                component: ModelItemView,
              },
              {
                name: ROUTES.MODEL_EDIT,
                path: "edit",
                component: ModelEditView,
                meta: {
                  requiresModel: true,
                  titleFunc: (_, getters) => {
                    return `Edit model ${getters.selectedModel.name}`;
                  },
                  breadcrumbs: [{ title: "Edit" }],
                } as RouteMeta,
              },
              {
                name: ROUTES.MODEL_LOGIN,
                path: "password",
                component: ModelLoginView,
                meta: {
                  requiresModel: true,
                  title: "Change password",
                  breadcrumbs: [{ title: "Password" }],
                } as RouteMeta,
              },
              {
                name: ROUTES.MODEL_MESSENGER,
                path: "messenger",
                component: ModelMessengerView,
                meta: {
                  title: "Messenger",
                  breadcrumbs: [{ title: "Messenger" }],
                } as RouteMeta,
              },
              {
                path: "campaigns",
                meta: {
                  title: "Campaigns",
                  breadcrumbs: [
                    {
                      title: "Campaigns",
                      linkFunc: (params) => ({
                        name: ROUTES.MODEL_CAMPAIGN_LIST,
                        params: { modelId: params.modelId || "0" },
                      }),
                    },
                  ],
                } as RouteMeta,
                children: [
                  {
                    path: "",
                    name: ROUTES.MODEL_CAMPAIGN_LIST,
                    component: ModelCampaignListView,
                  },
                  {
                    name: ROUTES.CAMPAIGN_ADD,
                    path: "add",
                    component: ModelCampaignAddView,
                    meta: {
                      title: "Add new campaign",
                      breadcrumbs: [{ title: "Add" }],
                    } as RouteMeta,
                  },
                  {
                    name: ROUTES.CAMPAIGN_WRAPPER_VIEW,
                    path: ":campaignId",
                    component: ModelCampaignWrapperView,
                    meta: {
                      titleFunc: (_, getters) =>
                        `Campaign ${getters.selectedCampaign.name}`,
                      breadcrumbs: [
                        {
                          titleFunc: (_, getters) =>
                            getters.selectedCampaign.name,
                          linkFunc: (params) => ({
                            name: ROUTES.CAMPAIGN_VIEW,
                            params: {
                              modelId: params.modelId || "0",
                              campaignId: params.campaignId || "0",
                            },
                          }),
                        },
                      ],
                    } as RouteMeta,
                    children: [
                      {
                        name: ROUTES.CAMPAIGN_VIEW,
                        path: "",
                        component: ModelCampaignItemView,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
