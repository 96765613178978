export const PRICES = [
  { title: "Free", value: 0 },
  { title: "3 credits", value: 3 },
  { title: "5 credits", value: 5 },
  { title: "10 credits", value: 10 },
  { title: "15 credits", value: 15 },
  { title: "20 credits", value: 20 },
  { title: "25 credits", value: 25 },
  { title: "30 credits", value: 30 },
];

export const USER_DATA_KEY = "userData";
