import { DUMMY_CONTACT } from "./contact";
import {
  Campaign,
  CampaignShort,
  CampaignStatus,
  ContactSorting,
} from "@/apiGen";

export const DUMMY_CAMPAIGN: Campaign = {
  id: "0",
  name: "...",
  status: CampaignStatus.Finished,
  status_note: "",
  user_id: "1",
  model_id: "1",
  contacts: [],
  settings: {
    limit: 0,
    loyalty_levels: [],
    sorting: ContactSorting.LastMessageAtDesc,
  },
  message: {
    text: "Dummy Campaign",
    price: 0,
    file_path: "",
    file_name: "",
    file_size: 0,
    credits_cost: 0,
  },
  result: {
    success: [],
    failed: [],
    reached: [],
    retries: [],
    errors: [],
  },
  created_at: new Date().toISOString(),
  started_at: new Date().toISOString(),
  finished_at: new Date().toISOString(),
};
export const DUMMY_CAMPAIGN_SHORT: CampaignShort = {
  id: "0",
  name: "...",
  status: CampaignStatus.Finished,
  status_note: "",
  user_id: "1",
  model_id: "1",
  contacts_count: 100,
  sent_count: 0,
  created_at: new Date().toISOString(),
  started_at: new Date().toISOString(),
  finished_at: new Date().toISOString(),
};

export function enrichCampaign(campaign: CampaignShort): Campaign {
  return {
    ...DUMMY_CAMPAIGN,
    id: campaign.id,
    name: campaign.name,
    status: campaign.status,
    status_note: campaign.status_note,
    user_id: campaign.user_id,
    model_id: campaign.model_id,
    created_at: campaign.created_at,
    started_at: campaign.started_at,
    finished_at: campaign.finished_at,
    contacts: Array.from(
      { length: campaign.contacts_count },
      () => DUMMY_CONTACT,
    ),
    result: {
      success: Array.from(
        { length: campaign.sent_count },
        () => DUMMY_CONTACT.id,
      ),
      retries: [],
      failed: [],
      reached: [],
      errors: [],
    },
  };
}

export function createDummies(count: number): Array<CampaignShort> {
  return Array.from({ length: count }, (_, i) => ({
    ...DUMMY_CAMPAIGN_SHORT,
    id: (-i - 1).toString(),
  }));
}
