import { DUMMY_CONTACT } from "./contact";
import { UserModel, UserModelShort } from "@/apiGen";

export const DUMMY_USER_MODEL: UserModel = {
  id: "0",
  email: "Loading...",
  name: "Loading...",
  contacts: [],
  contacts_count: 0,
};

export const DUMMY_USER_MODEL_SHORT: UserModelShort = {
  id: "0",
  email: "Loading...",
  name: "Loading...",
  contacts_count: 0,
};

export function enrichUserModel(model: UserModelShort): UserModel {
  return {
    ...DUMMY_USER_MODEL,
    id: model.id,
    email: model.email,
    name: model.name,
    contacts: Array.from({ length: model.contacts_count }, () => DUMMY_CONTACT),
    contacts_count: model.contacts_count,
  };
}

export function createDummies(count: number): Array<UserModelShort> {
  return Array.from({ length: count }, (_, i) => ({
    ...DUMMY_USER_MODEL_SHORT,
    id: (-i - 1).toString(),
  }));
}
