<template>
  <div class="text-h4">
    {{ title }}
  </div>
</template>
<script setup lang="ts">
import { inject } from "@/injection";
import { computed } from "vue";

const store = inject("store");

const title = computed<string>(() => {
  const routeMeta = store.getters.currentRouteMeta;
  if (routeMeta.title) return routeMeta.title;
  return "WebChat AI";
});
</script>
