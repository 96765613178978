<template>
  <v-card>
    <v-card-title>Password for {{ model.name }}</v-card-title>
    <v-card-text>
      <model-login-form @submit="login" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { router } from "@/router";
import ModelLoginForm from "@/components/ModelLoginForm.vue";
import { inject } from "@/injection";

const model = inject("model");

async function login() {
  router.replace(".");
}
</script>
