<template>
  <v-navigation-drawer v-model="show">
    <v-list-item
      prepend-icon="mdi-security"
      v-if="isAdmin"
      :to="{ name: ADMIN_ROUTES.USERS }"
      title="Admin"
    ></v-list-item>
    <v-divider v-if="isAdmin"></v-divider>
    <v-list-item
      prepend-icon="mdi-account-heart"
      v-if="isAuthenticated"
      :to="{ name: ROUTES.MODEL_LIST }"
      title="Models"
    ></v-list-item>
    <v-list-item
      prepend-icon="mdi-message-processing-outline"
      v-if="isAuthenticated"
      :to="{ name: ROUTES.CAMPAIGN_LIST }"
      title="Campaigns"
    ></v-list-item>
    <v-list-item
      prepend-icon="mdi-account-box"
      v-if="isAuthenticated"
      :to="{ name: ROUTES.PROFILE }"
      title="My profile"
    ></v-list-item>
    <v-divider></v-divider>
    <v-list-item
      prepend-icon="mdi-logout"
      v-if="isAuthenticated"
      @click="logout"
      title="Logout"
    ></v-list-item>
    <v-list-item
      prepend-icon="mdi-login"
      v-if="!isAuthenticated"
      :to="{ name: ROUTES.LOGIN }"
      title="Login"
    ></v-list-item>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { ADMIN_ROUTES, ROUTES, router } from "@/router";
import { inject } from "@/injection";
import { Mutation } from "@/store/mutations";
import { useToast } from "vue-toast-notification";

const toast = useToast();
const store = inject("store");

const isAdmin = computed(() => store.getters.isAdmin);
const isAuthenticated = computed(() => store.getters.isAuthenticated);

interface IProps {
  show: boolean;
}

const props = defineProps<IProps>();
const show = computed(() => props.show);

function logout() {
  store.commit(Mutation.Logout, undefined);
  toast.success("Successfully logged out");
  router.push({ name: ROUTES.LOGIN });
}
</script>
