<template>
  <v-row>
    <v-col>
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component
            :key="route.path"
            :is="Component"
            v-if="!loading || !route.meta.requiresUser"
            :loading="loading"
          />
        </transition>
      </router-view>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { inject, provide } from "@/injection";
import { User } from "@/apiGen";
import { Mutation } from "@/store/mutations";
import { ADMIN_ROUTES, router } from "@/router";
import { getActAsApi } from "@/apiClient";

const api = inject("adminApi");
const store = inject("store");
const loading = ref(true);
const user = computed<User>({
  get: () => {
    return store.getters.selectedUser;
  },
  set: (value) => {
    store.commit(Mutation.SetSelectedUser, value);
  },
});

provide("user", user);

const actAsApi = getActAsApi(user.value);
provide("api", actAsApi);

api
  .adminGetUser(user.value.id)
  .then((response) => {
    user.value = response.data;
  })
  .catch((e) => {
    console.error(e);
    router.push({
      name: ADMIN_ROUTES.USERS,
    });
  })
  .finally(() => {
    loading.value = false;
  });
</script>
