<template>
  <v-app>
    <v-main>
      <v-app-bar :elevation="2">
        <v-app-bar-nav-icon
          @click="showDrawer = !showDrawer"
        ></v-app-bar-nav-icon>
        WebChat AI
        <v-spacer></v-spacer>
        <div v-if="isAuthenticated">
          <v-chip prepend-icon="mdi-account-box" class="ma-2">
            {{ user.email }}
          </v-chip>
          <v-chip
            prepend-icon="mdi-bitcoin"
            :title="`${user.credits} credits`"
            class="ma-2"
          >
            {{ user.credits }}
          </v-chip>
        </div>
        <v-btn
          v-if="user.email"
          icon
          @click="logout"
          :title="`Logout from ${user.email}`"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      <navigation-drawer :show="showDrawer"></navigation-drawer>
      <v-container>
        <v-row>
          <v-col>
            <page-title></page-title>
            <breadcrumbs-title></breadcrumbs-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <router-view v-slot="{ Component, route }">
              <transition name="fade">
                <component :key="route.path" :is="Component"></component>
              </transition>
            </router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/store";
import { ROUTES, router } from "@/router";
import { provide } from "@/injection";
import PageTitle from "@/components/PageTitle.vue";
import BreadcrumbsTitle from "@/components/BreadcrumbsTitle.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import { useToast } from "vue-toast-notification";

import { getPublicApi } from "@/apiClient";
import { Mutation } from "@/store/mutations";

const store = useStore();
const api = getPublicApi();
const toast = useToast();
const showDrawer = ref(false);

provide("store", store);
provide("router", router);
provide("api", api);

const user = computed({
  get: () => store.state.userData,
  set(value) {
    store.commit(Mutation.SetUserData, value);
  },
});
provide("user", user);
provide("routes", ROUTES);

const isAuthenticated = computed(() => store.getters.isAuthenticated);

if (isAuthenticated.value) {
  api
    .getProfile()
    .then((response) => {
      user.value = {
        ...user.value,
        credits: response.data.credits,
      };
    })
    .catch((e) => {
      console.error(e);
    });
}

function logout() {
  store.commit(Mutation.Logout, undefined);
  toast.success("Successfully logged out");
  router.push({ name: ROUTES.LOGIN });
}
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    display: block;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
    visibility: hidden;
    height: 0px;
  }
}
.fade-enter-active {
  opacity: 0;
  display: block;
  animation: fadeIn 0.3s;
}

.fade-leave-active {
  animation: fadeOut 0.15s;
  display: none;
}
</style>
