<template>
  <v-row>
    <v-col cols="12">
      <v-form validate-on="submit" @submit.prevent="onSubmit">
        <v-text-field v-model="model.email" label="Email" disabled autocomplete="off" />
        <v-text-field
          v-model="formData.name"
          label="Name"
          required
          :rules="[(v) => !!v || 'Name is required']"
        />
        <v-text-field v-model="formData.token" label="Token" />
        <v-btn :loading="loading" type="submit" color="info" class="mx-2"> Save </v-btn>
        <v-btn :to="{ name: ROUTES.MODEL_VIEW, params: { modelId: model.id } }">
          Cancel
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>
<script setup lang="ts">
import { ROUTES } from "@/router";
import { ref } from "vue";
import { UserModel, UpdateModelData } from "@/apiGen";
import { SubmitEventPromise } from "@/types";
import { inject } from "@/injection";

const model = inject("model");
const api = inject("api");

const emit = defineEmits<(event: "submit", model: UserModel) => void>();

const loading = ref(false);

const formData = ref<UpdateModelData>({
  name: model.value.name,
  token: "",
});

async function onSubmit(event: SubmitEventPromise) {
  const submitData = await event;
  if (!submitData.valid) return;
  loading.value = true;

  const modelData: UpdateModelData = {
    name: formData.value.name,
  };

  if (formData.value.token) {
    modelData.token = formData.value.token;
    modelData.token_updated_at = new Date().toISOString();
  }

  return api
    .updateModel(model.value.id, modelData)
    .then((response) => {
      emit("submit", response.data);
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
