import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { adminRoutes } from "./admin";
import { userRoutes } from "./user";

export { ADMIN_ROUTES } from "./admin";
export { ROUTES } from "./user";
import type { RouteMeta } from "@/types";
export type { Router, RouteLocationRaw, RouteParams } from "vue-router";

export const routes: RouteRecordRaw[] = [...userRoutes, ...adminRoutes];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export function getCurrentRouteMeta(): RouteMeta {
  const matched = router.currentRoute.value.matched;
  const result = {
    admin: false,
    title: "WebChat AI",
    breadcrumbs: [],
  } as RouteMeta;
  matched.forEach((route) => {
    const meta = route.meta as unknown as RouteMeta;
    if (meta.admin) result.admin = meta.admin;
    if (meta.title) {
      result.title = meta.title;
      result.titleFunc = undefined;
    }
    if (meta.titleFunc) result.titleFunc = meta.titleFunc;
    if (meta.breadcrumbs && result.breadcrumbs) {
      result.breadcrumbs.push(...meta.breadcrumbs);
    }
  });
  return result;
}
