<template>
  <v-select
    label="Sort by"
    v-model="value"
    item-text="title"
    item-value="value"
    :items="choices"
  ></v-select>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { inject } from "@/injection";
import { ContactSorting } from "@/apiGen";
import { Mutation } from "@/store/mutations";

const store = inject("store");
const choices = [
  { title: "Oldest messaged", value: ContactSorting.LastMessageAtDesc },
  { title: "Newest messaged", value: ContactSorting.LastMessageAt },
  { title: "Name A-Z", value: ContactSorting.Name },
  { title: "Name Z-A", value: ContactSorting.NameDesc },
];

const value = computed({
  get() {
    return store.state.filters.sorting;
  },
  set(value) {
    store.commit(Mutation.UpdateFilters, { sorting: value });
  },
});
</script>
