<template>
  <v-btn
    @click="onButtonClick"
    :disabled="disabled"
    :loading="loading"
    :color="color"
  >
    <slot></slot>
    <v-dialog
      v-if="confirm"
      v-model="dialog"
      width="auto"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-text>
          {{ confirm }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" @click="closeDialog">Cancel</v-btn>
          <v-btn :color="color" @click="onClickRun">
            <slot></slot>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>
<script setup lang="ts">
import { ref } from "vue";

interface IProps {
  color?: string;
  disabled?: boolean;
  confirm?: string | null;
  onClick?: () => Promise<void>;
}

const props = withDefaults(defineProps<IProps>(), {
  color: "info",
  disabled: false,
  confirm: null,
  onClick: () =>
    new Promise((resolve) => setTimeout(resolve, 100)) as Promise<void>,
});

const loading = ref(false);
const dialog = ref(false);
const onButtonClick = props.confirm ? showDialog : onClickRun;

function showDialog() {
  dialog.value = true;
  loading.value = true;
}

function closeDialog() {
  dialog.value = false;
  loading.value = false;
}

async function onClickRun() {
  loading.value = true;
  try {
    await props.onClick();
  } catch (e) {
    console.error(e);
  }
  loading.value = false;
}
</script>
