<template>
  <v-card>
    <v-card-title>New campaign</v-card-title>
    <v-card-text>
      <campaign-create-form @submit="onSubmit" />
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
import { router } from "@/router";
import CampaignCreateForm from "@/components/CampaignCreateForm.vue";
import { Campaign } from "@/apiGen";
import { inject } from "@/injection";

const ROUTES = inject("routes");

async function onSubmit(campaign: Campaign) {
  router.push({
    name: ROUTES.CAMPAIGN_VIEW,
    params: { modelId: campaign.model_id, campaignId: campaign.id },
  });
}
</script>
